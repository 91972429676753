import uuid from "uuid/v4";
/**
 * Modules.type:
 *      0:标题
 *      1:广告
 *      2:空白间隔
 *      3:导航
 * 
 *      4:通栏图片
 *      5:二分图片
 *      6:三分图片
 *      7:四分图片
 * 
 *      8:产品模块1
 *      9:旅游线路模块1
 *      10:视频模块
 *      
 *      11:分类模块
 */
export const TitleData = () => {
    return {
        id: uuid(),
        name: '标题组件',
        type: 0,
        content: {
            title: '测试标题'
        }
    }
};
export const ImageDataItem = () => {
    return {
        linkType: 0,
        link: '###',
        title: '测试广告',
        showTitle: '测试广告',
        pic: 'http://www.scyts.com/UploadFiles/Others/20200116174602_76392.jpg'
    };
}
export const AdData = () => {
    return {
        id: uuid(),
        name: '广告组件',
        type: 1,
        content: {
            showType: 1,
            space: 0,
            margin: 10,
            style: [],
            dataset: [
                ImageDataItem(),
                ImageDataItem()
            ]
        }
    }
};
export const SpaceData = () => {
    return {
        id: uuid(),
        name: '空白组件',
        type: 2,
        content: {
            height: 20
        }
    }
};
export const NavData = () => {
    return {
        id: uuid(),
        name: '导航组件',
        type: 3,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            gapSpace: 0,//间隙边距
            style: [],
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    title: '东南亚',
                    showTitle: '东南亚',
                    pic: 'http://www.scyts.com/mobile/images/ntab_dny.png'
                },
                {
                    linkType: 0,
                    link: '',
                    title: '海岛',
                    showTitle: '海岛',
                    pic: 'http://www.scyts.com/mobile/images/ntab_hd.png'
                },
                {
                    linkType: 0,
                    link: '',
                    title: '欧洲',
                    showTitle: '欧洲',
                    pic: 'http://www.scyts.com/mobile/images/ntab_oz.png'
                },
                {
                    linkType: 0,
                    link: '',
                    title: '美洲',
                    showTitle: '美洲',
                    pic: 'http://www.scyts.com/mobile/images/ntab_mz.png'
                }
            ]
        }
    }
}

export const Image01Item = () => {
    return {
        linkType: 0,
        link: '',
        title: '通栏图片',
        showTitle: '通栏图片',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image01.png'
    };
}
export const Image01Data = () => {
    return {
        id: uuid(),
        name: '通栏图片',
        type: 4,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            style: [],
            dataset:[
                Image01Item()
            ]
        }
    }
}

export const Image02Item = () => {
    return {
        linkType: 0,
        link: '',
        title: '二分图片',
        showTitle: '二分图片',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image02.png'
    };
}
export const Image02Data = () => {
    return {
        id: uuid(),
        name: '2分图片',
        type: 5,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            gapSpace: 0,//间隙边距
            style: [],
            dataset:[
                Image02Item(),
                Image02Item()
            ]
        }
    }
}

export const Image03Item = () => {
    return {
        linkType: 0,
        link: '',
        title: '三分图片',
        showTitle: '三分图片',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image03.png'
    };
}
export const Image03Data = () => {
    return {
        id: uuid(),
        name: '3分图片',
        type: 6,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            gapSpace: 0,//间隙边距
            style: [],
            dataset:[
                Image03Item(),
                Image03Item(),
                Image03Item()
            ]
        }
    }
}

export const Image04Item = () => {
    return {
        linkType: 0,
        link: '',
        title: '四分图片',
        showTitle: '四分图片',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png'
    };
}
export const Image04Data = () => {
    return {
        id: uuid(),
        name: '4分图片',
        type: 7,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            gapSpace: 0,//间隙边距
            style: [],
            dataset:[
                Image04Item(),
                Image04Item(),
                Image04Item(),
                Image04Item()
            ]
        }
    }
}

export const GoodsDataItem = () => {
    return {
        linkType: 0,
        link: '',
        title: '产品标题产品标题产品标题产品标题产品标题产品标题产品标题产品标题产品标题产品标题产品标题产品标题',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
        price: 0.00,
        describe: '产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介',
        tags: [],//商品标签
        currencySymbol: '￥',//货币符号
        currencyShortName: 'CNY'//货币缩写
    }
}

export const Goods01Data = () => {
    return {
        id: uuid(),
        name: '商品组件',
        type: 8,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            borderRadius: 0,//圆角半径
            showType: 2,/*
            显示方式:
            1.大图模式-1行1个
            2.小图模式-1行2个
            3.小图模式-1行3个
            4.大图列表
            5.小图列表
            6.瀑布流
            */
            dataset:[
                GoodsDataItem(),
                GoodsDataItem(),
                GoodsDataItem()
            ]
        }
    }
}

export const LineDataItem = () => {
    return {
        linkType: 0,
        link: '',
        title: '线路标题线路标题线路标题线路标题线路标题线路标题线路标题线路标题线路标题',
        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image03.png',
        price: 688888,//价格
        describe: '线路简介线路简介线路简介线路简介线路简介线路简介线路简介线路简介线路简介',
        tags: ['标签1', '标签2'],//标签
        setOut: '上海出发',//出发地
        typesName: '',//类型名称(出境、国内、周边...)
        currencySymbol: '￥',//货币符号
        currencyShortName: 'CNY'//货币缩写
    }
}

export const Line01Data = () => {
    return {
        id: uuid(),
        name: '旅游线路组件',
        type: 9,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            borderRadius: 0,//圆角半径
            showType: 2,/*
            显示方式:
            1.大图模式-1行1个
            2.小图模式-1行2个
            3.小图模式-1行3个
            4.大图列表
            5.小图列表
            6.瀑布流
            */
            dataset: [
                LineDataItem(),
                LineDataItem(),
                LineDataItem()
            ]
        }
    }
}

export const VideoData = () => {
    return {
        id: uuid(),
        name: '视频组件',
        type: 10,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            title: '',//视频名称
            poster: '',//封面图片url
            src: ''//视频资源地址
        }
    }
}

export const CategoryDataItem = (isCur) => {
    return {
        title: '一级分类',
        cur: isCur,
        sub: [
            {
                title: '二级分类1',
                sub: [
                    {
                        title: '三级分类1',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类2',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类3',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类4',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类5',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    }
                ]
            },
            {
                title: '二级分类2',
                sub: [
                    {
                        title: '三级分类1',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类2',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类3',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    }
                ]
            },
            {
                title: '二级分类3',
                sub: [
                    {
                        title: '三级分类1',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类2',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    },
                    {
                        title: '三级分类3',
                        pic: 'https://img1.lymdd.net/images/mall_temp/replace-image04.png',
                        linkType: 0,
                        link: ''
                    }
                ]
            }
        ]
    }
}

export const CategoryData = () => {
    return {
        id: uuid(),
        name: '类别组件',
        type: 11,
        content: {
            marginLR: 0,//左右边距
            marginTop: 0,//上边距
            marginBottom: 0,//下边距
            //height: 500,//高度
            dataset: [
                CategoryDataItem(true),
                CategoryDataItem(false),
                CategoryDataItem(false)
            ]
        }
    }
}

export const defaultData = {
    page: {
        title: '',
        description: '',
        url: '',
        bgColor: '#f1f1f1'
    },
    Modules: [
        TitleData(),
        AdData(),
        SpaceData(),
        NavData(),
        Image01Data(),
        Image02Data(),
        Image03Data(),
        Image04Data(),
        Goods01Data(),
        Line01Data(),
        VideoData(),
        CategoryData()
    ]
}