import React from 'react';

function Image03({ content }){
    if(content.dataset.length > 0){
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-image03"
            >
                <ul>
                    {
                        content.dataset.map((item, index) => {
                            return (<li key={index}
                                style={{
                                    paddingLeft:content.gapSpace/2,
                                    paddingRight:content.gapSpace/2
                                }}
                            >
                                <img src={item.pic} alt={item.title} />
                            </li>)
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default Image03;