import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { Row, Col, Layout, Form, Spin } from 'antd'
import PhoneBox from './PhoneBox'
import ToolBox from './ToolBox'
import { TemplateContext } from './Template'
import PropertyBox from './PropertyBox'
import HeaderBox from './HeaderBox'
import axios from '../../utils/request'


function Workbench({ props }){
    const { Content, Sider } = Layout;
    const [isLoading, setIsLoading] = useState(true)

    //当前店铺ID
    let storeId = props.match.params.id;
    //设备ID
    let device = props.match.params.id2;
    //页面ID
    let pageId = props.match.params.id3;

    const { data, dispatch, storeIdDispatch, deviceDispatch, pageIdDispatch } = useContext(TemplateContext);
    //设置全局的storeId
    storeIdDispatch(storeId);
    //设置全局的设备ID
    deviceDispatch(device);
    //设置全局的页面ID
    pageIdDispatch(pageId);

    useEffect(() => {
        if(pageId > 0){
            axios.get(`/api/CustomPage/${pageId}`)
            .then(
                (res) => {
                    dispatch(res.data);
                    setIsLoading(false);
                }
            )
        }
        else{
            setIsLoading(false);
        }
    }, []);

    //拖放的结束事件
    const onDragEnd = (result) => {
        if(!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId){
            //不同源拖放
        }
        else{
            //同源拖放，对Json重新排序
            const copiedItems = [...data.Modules];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            dispatch({
                page: data.page,
                Modules: copiedItems
            });
        }
    }
    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <HeaderBox props={props} />
            <DragDropContext
                    onDragEnd={result => onDragEnd(result)}
                >    
                <Layout>
                    <Sider width={200} style={{ background: '#fff' }}>
                        <ToolBox />
                    </Sider>
                    <Layout style={{ borderLeft:'1px solid #ccc' }}>
                        <Content>
                            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                                <Row>
                                    <Col span={8}>
                                        <Row>
                                            <Col span={24}>
                                                <PhoneBox />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={13}>
                                        <div className="property-box">
                                            <Form 
                                                layout="horizontal"
                                                labelCol={{span:4}}
                                                wrapperCol={{span:20}}
                                            >
                                                <PropertyBox />
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </DragDropContext>
        </Spin>
    )
}

export default Workbench;