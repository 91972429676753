import React, { forwardRef, useImperativeHandle } from 'react'
import { Form, Col, Row, Input, Button } from 'antd';

const PaySettingStripePayForm = forwardRef(({ form, data, onSubmit, onClose }, ref) => {
    useImperativeHandle(ref, () => ({
        form
    }))
    const { getFieldDecorator } = form;
    return (
        <Form id="stripePayForm" name="stripePayForm" onSubmit={onSubmit} layout="vertical">
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="ApiKey">
                {getFieldDecorator('appId', {
                    rules: [{ required: true, message: '请输入ApiKey' }],
                    initialValue: data.appID
                })(<Input placeholder="ApiKey" />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="EndPointSecret">
                {getFieldDecorator('appSecret', {
                    rules: [{ required: true, message: '请输入EndPointSecret' }],
                    initialValue: data.appSecret
                })(<Input placeholder="EndPointSecret" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="PublishableKey">
                {getFieldDecorator('key', {
                    rules: [{ required: true, message: '请输入PublishableKey' }],
                    initialValue: data.key
                })(<Input placeholder="PublishableKey" />)}
                </Form.Item>
            </Col>
            </Row>
            <div
                style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
                </Button>
                <Button htmlType="submit" type="primary">
                提交
                </Button>
            </div>
        </Form>
    )
})

export default Form.create()(PaySettingStripePayForm);