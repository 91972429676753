import React from 'react';
import { Slider, Radio } from 'antd'

/**
 * 商品属性修改
 */
function GoodsPropertyControl({contentData, onChangeProp}) {

    /**
     * 改变列表样式
     * @param {} e 
     */
    const onShowTypeChange = e => {
        contentData.showType = e.target.value;
        onChangeProp(contentData);
    }

    /**
     * 改变上边距
     * @param {} value 
     */
    const onMarginTopChange = value => {
        contentData.marginTop = value;
        onChangeProp(contentData);
    }

    /**
     * 改变下边距
     * @param {}} value 
     */
    const onMarginBottomChange = value => {
        contentData.marginBottom = value;
        onChangeProp(contentData);
    }

    /**
     * 改变左右边距
     * @param {*} value 
     */
    const onMarginLRChange = value => {
        contentData.marginLR = value;
        onChangeProp(contentData);
    }
    
    /**
     * 改变圆角半径
     * @param {*} value 
     */
    const onBorderRadiusChange = value => {
        contentData.borderRadius = value;
        onChangeProp(contentData);
    }

    return (
        <div className="ctrl-list-property">
            <div className="ctrl-form-item">
                <label>上边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginTop} onChange={onMarginTopChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>下边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginBottom} onChange={onMarginBottomChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>左右边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginLR} onChange={onMarginLRChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>圆角半径：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.borderRadius} max={50} onChange={onBorderRadiusChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>列表样式：</label>
                <div className="ctrl-form-controls">
                    <Radio.Group value={contentData.showType} onChange={onShowTypeChange}>
                        <Radio style={{textAlign:'left'}} value={1}>1行1个</Radio>
                        <Radio style={{textAlign:'left'}} value={2}>1行2个</Radio>
                        <Radio style={{textAlign:'left'}} value={3}>1行3个</Radio>
                        <Radio style={{textAlign:'left'}} value={4}>大图列表</Radio>
                        <Radio style={{textAlign:'left'}} value={5}>小图列表</Radio>
                        <Radio style={{textAlign:'left'}} value={6}>瀑布流</Radio>
                    </Radio.Group>
                </div>
            </div>
        </div>
    )
}

export default GoodsPropertyControl;