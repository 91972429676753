import React from 'react';

//广告组件
function Ad({ content }){
    if(content.dataset.length > 0){
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-ad">
                <img src={content.dataset[0].pic} alt={content.dataset[0].title} />
            </div>
        );
    }
    else {
        return (
            <div className="diy-conitem-ad">
                {/* <img src={props.content.dataset[0].pic} alt={props.content.dataset[0].title} /> */}
            </div>
        );
    }
    
}
export default Ad;