import React, { useContext, useState } from 'react';
import { Form, Divider, Input, Upload, Icon, message } from 'antd'
import uuid from "uuid/v4";
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import BasePropertyControl from './BasePropertyControl'

function VideoEdit({ form }) {
    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);

    const { Item } = Form
    
    /**
     * 控件属性改变的回调
     * @param {*} contentData 
     */
    const onChangeProp = contentData => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = contentData;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        //console.log(copiedSelectedData)
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 上传文件之前的钩子，用于判断图片大小
     * @param {*} file 
     */
    const beforeUpload = (file) => {
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
            message.error('图片大于3MB');
        }
        return isLt3M;
    }

    /**
     * 上传中、完成、失败都会调用这个函数。
     * @param {*} info 
     */
    const onUploadChange = ({ file, fileList }) => {
        if(file.status === "removed"){
            setFileList(null);
            selectedData.content.poster = '';
            const copiedItems = [...data.Modules];
            let foundIndex = copiedItems.findIndex(p => p.id === selectedData.id);
            copiedItems[foundIndex] = selectedData;
            dispatch({
                page: data.page,
                Modules: copiedItems
            });
        }
        else if(file.status === "uploading"){
            setFileList([...fileList]);
        }
        else if(file.status === "done"){
            setFileList([...fileList]);
            selectedData.content.poster = file.response.url
            const copiedItems = [...data.Modules];
            let foundIndex = copiedItems.findIndex(p => p.id === selectedData.id);
            copiedItems[foundIndex] = selectedData;
            dispatch({
                page: data.page,
                Modules: copiedItems
            });
        }
    }

    //当前项目的图片
    const [fileList, setFileList] = useState(selectedData.content.poster.length > 0 ? [{
        uid: uuid(),
        name: selectedData.content.title,
        status: 'done',
        url: selectedData.content.poster
    }] : null);

    /**
     * 没有图片时展示的上传组件
     */
    const uploadButton = (
        <div>
          <Icon type="plus" />
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    const onTitleChange = e => {
        selectedData.content.title = e.target.value;
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === selectedData.id);
        copiedItems[foundIndex] = selectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    const onVideoSrcChange = e => {
        selectedData.content.src = e.target.value;
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === selectedData.id);
        copiedItems[foundIndex] = selectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }
    

    return (
        <div className="ctrl-list">
            <Divider>{selectedData.name}</Divider>
            <BasePropertyControl key="baseProperty" contentData={selectedData.content} onChangeProp={onChangeProp} />
            <Item label="视频封面">
                <Upload 
                    action="/api/File/AntdUpload"
                    accept="image/jpg,image/png,image/jpeg"
                    showUploadList={{
                        showDownloadIcon: false//去除下载按钮
                    }}
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    fileList={ fileList }
                >
                    { fileList == null ? uploadButton : null }
                </Upload>
            </Item>
            <Item label="视频标题">
                <Input value={selectedData.content.title} onChange={onTitleChange}  />
            </Item>
            <Item label="视频地址" extra="视频源必须为mp4，否则可能无法正常播放">
                <Input value={selectedData.content.src} onChange={onVideoSrcChange} />
            </Item>
        </div>
    )
}

export default Form.create({})(VideoEdit);