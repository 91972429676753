import React from 'react';


function Category({ content }) {
    let currentIndex = content.dataset.findIndex(p => p.cur === true);
    //const [currentCategory, setCurrentCategory] = useState(content.dataset[currentIndex]);

    //console.log("cur",currentCategory)

    /**
     * 分类主体
     */
    const CategoryContent = () => {
        if(currentIndex >= 0) {
            return (
                content.dataset[currentIndex].sub.map((item, index) => {
                    return (
                        <div key={index} className="category-content-item">
                            <h4>{item.title}</h4>
                            <ul className="category-level3">
                                {
                                    item.sub.map((item3, index) => {
                                        return (
                                            <li key={index}>
                                                <img src={item3.pic} alt={item3.title} />
                                                <span>{item3.title}</span>
                                            </li>
                                        )
                                    })
                                }
                                
                            </ul>
                        </div>
                    )
                })
            )
        }
        else {
            return <></>
        }
    }

    return (
        <div 
        style={{
            marginTop:content.marginTop,
            marginBottom:content.marginBottom,
            marginLeft:content.marginLR,
            marginRight:content.marginLR
        }} 
        className="diy-conitem-category">
            <div className="category-tab">
                <ul>
                    {
                        content.dataset.map((item, index) => {
                            return (
                                <li key={index} className={item.cur ? 'cur' : ''}>
                                    {item.title}
                                </li>
                            )
                        })
                    }
                    
                </ul>
            </div>
            <div className="category-content">
                <CategoryContent />
                {/* {
                    content.dataset[currentIndex].sub.map((item, index) => {
                        return (
                            <div key={index} className="category-content-item">
                                <h4>{item.title}</h4>
                                <ul className="category-level3">
                                    {
                                        item.sub.map((item3, index) => {
                                            return (
                                                <li key={index}>
                                                    <img src={item3.pic} alt={item3.title} />
                                                    <span>{item3.title}</span>
                                                </li>
                                            )
                                        })
                                    }
                                    
                                </ul>
                            </div>
                        )
                    })
                } */}
                
            </div>
        </div>
    )
}

export default Category;