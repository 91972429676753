import React, { useContext } from 'react';
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import { Input, Icon, Button } from 'antd'
import { CategoryDataItem } from '../../Pages/StoreTempEdit/TemplateData'
import CategoryLevel3Control from './CategoryLevel3Control'

function CategoryLevel2Control(){
    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);
    const { content } = selectedData;

    let currentIndex = content.dataset.findIndex(p => p.cur === true);

    //修改二级分类title
    const onTitleChange = (index, e) => {

        content.dataset[currentIndex].sub[index].title = e.target.value;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //二级分类向上移动
    const onUp = (index) => {
        //当前下标为0则无需移动
        if(index === 0) {
            return;
        }

        const copidsubs = [...content.dataset[currentIndex].sub]
        const [subRemoved] = copidsubs.splice(index, 1);
        copidsubs.splice(index - 1, 0, subRemoved);

        content.dataset[currentIndex].sub = copidsubs;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //二级分类向下移动
    const onDown = (index) => {
        //当前下标是最后一个下标则无需移动
        if(index + 1 === content.dataset[currentIndex].sub.length) {
            return;
        }

        const copidsubs = [...content.dataset[currentIndex].sub]
        const [subRemoved] = copidsubs.splice(index, 1);
        copidsubs.splice(index + 1, 0, subRemoved);

        content.dataset[currentIndex].sub = copidsubs;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //删除二级分类
    const onRemoved = (index) => {
        const copidsubs = [...content.dataset[currentIndex].sub]
        copidsubs.splice(index, 1)

        content.dataset[currentIndex].sub = copidsubs;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //添加二级分类
    const onAdd = () => {
        let subDataItem = CategoryDataItem().sub[0];
        const copidsubs = [...content.dataset[currentIndex].sub]
        copidsubs.push(subDataItem);

        content.dataset[currentIndex].sub = copidsubs;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //添加三级分类
    const onAddLevel3 = (index) => {
        let subDataItem = CategoryDataItem().sub[0].sub[0];
        const copidsubs = [...content.dataset[currentIndex].sub[index].sub]
        copidsubs.push(subDataItem);

        content.dataset[currentIndex].sub[index].sub = copidsubs;

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    if(currentIndex >= 0) {
        return (
            <div className="ctrl-category-right">
                {
                    content.dataset[currentIndex].sub.map((item, index) => {
                        return (
                            <div key={index + item} className="category-right-item">
                                <div className="category-level2-title">
                                    <Input value={item.title} onChange={e => onTitleChange(index, e)} suffix=
                                        {
                                        <>
                                            <Icon onClick={()=>onUp(index)} type="arrow-up" /> 
                                            <Icon onClick={()=>onDown(index)} type="arrow-down" /> 
                                            <Icon onClick={()=>onRemoved(index)} type="close" />
                                        </>
                                        } 
                                    />
                                </div>
                                {
                                    item.sub.map((item2, index2) => {
                                        return (
                                            <CategoryLevel3Control
                                                key={index + item2.title + index2}
                                                item={item2}
                                                index={index}
                                                index2={index2}
                                            />
                                        )
                                    })
                                }
                                <Button 
                                    style={{width:'100%'}} 
                                    type="dashed" 
                                    icon="plus" 
                                    onClick={() => onAddLevel3(index)} 
                                />
                            </div>
                        )
                    })
                }
                <Button style={{width:'100%'}} type="default" icon="plus" onClick={onAdd} />
            </div>
        )
    }
    else {
        return <div className="ctrl-category-right"></div>
    }
}

export default CategoryLevel2Control;