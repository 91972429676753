import React from 'react';

//空白间隔组件
function Space(props){
    return (
        <div style={{ height: props.content.height, lineHeight: props.content.height + 'px' }} className="diy-conitem-space">
            空白间隔
        </div>
    )
}

export default Space;