import React,{ useEffect, useState } from 'react';
import { Layout, Breadcrumb, Row, Button, Table, Popover, Icon, Modal, Checkbox, message } from 'antd';
import { Link } from 'react-router-dom'
import axios from '../utils/request'
import AdminMenu from '../Components/AdminMenu'
import '../static/style/pages/Common.css'

function WhiteCustomPage(props){
    const { Header, Content, Footer } = Layout;
    const [list, setList] = useState([]);
    //const [pagination, setPagination] = useState({});
    //const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [menuModalVisible, setMenuModalVisible] = useState(false);
    //菜单选项默认选中项
    const [checkedMenuOptions, setCheckedMenuOptions] = useState(['index', 'category', 'my']);
    
    //获取店铺ID
    let storeId = props.match.params.id;
    //设备
    let useDevice = props.match.params.id2;

    const getList = (pageIndex, pageSize) => {
        axios.get(`/api/CustomPage/List?pageIndex=${pageIndex}&pageSize=${pageSize}&useDevice=${useDevice}`, {
            headers:{
                'StoreID': storeId
            }
        })
        .then(
            (res) => {
                setList(res.data);
            }
        )
    }

    useEffect(() => {
        getList(pageIndex, pageSize);
    }, [pageIndex, pageSize])

    const { confirm } = Modal;
    const showDeleteConfirm = id => {
        confirm({
          title: '提示',
          content: '您确定要删除该项吗？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteItem(id);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    const columns = [
        {
            title: '页面名称',
            dataIndex: 'pageTitle',
            width: '20%',
        },
        {
            title: '页面简介',
            dataIndex: 'pageDesc',
            width: '20%',
        },
        {
            title: 'Url',
            dataIndex: 'url',
        },
        {
            title: '录入时间',
            dataIndex: 'inputTime'
        },
        {
            title: '操作',
            dataIndex: '',
            key: '',
            render: (text, record, index)=>{
                
                return (
                    <Button.Group>
                        <Button type="primary">
                            <Link to={`/Store/Template/${record.storeID}/${record.useDevice}/${record.id}`}>编辑</Link>
                        </Button>
                        <Button onClick={e => {showDeleteConfirm(record.id)}} type="danger">删除</Button>
                    </Button.Group>
                )
            }
        }
    ]

    /**
     * 删除项
     * @param {*} id 
     */
    const deleteItem = id => {
        axios.delete(`/api/CustomPage/${id}`, {
            headers:{
                'StoreID': storeId
            }
        })
        .then(
            (res) => {
                if(res.data.status === "success") {
                    getList(pageIndex, pageSize);
                    message.success('删除成功')
                }
                else {
                    message.error('删除失败')
                }
                
            }
        )
    }

    /**
     * 打开Modal
     * @param {*} e 
     */
    const menuButtonHandler = e => {
        axios.get(`/api/TenantSetting/Store/${storeId}/PageMenu${useDevice}`)
        .then(
            (res) => {
                if(res.data.data != null) {
                    console.log(res.data.data.value.split(','))
                    setCheckedMenuOptions(res.data.data.value.split(','));
                }

                setMenuModalVisible(true);
            }
        )
    }

    /**
     * Menu Modal保存事件
     * @param {*} e 
     */
    const menuModalOkHandler = e => {
        if(checkedMenuOptions.length < 2) {
            message.error('至少选择2个菜单');
            return false;
        }
        
        axios.post(`/api/TenantSetting/Store/${storeId}`, {
            key: `PageMenu${useDevice}`,
            value: checkedMenuOptions.join(',')
        })
        .then((res) => {
            if(res.data.status === 'success') {
                message.success('设置成功');
                setMenuModalVisible(false);
            }
            else {
                message.error('设置失败');
            }
        })
    }

    /**
     * 菜单选项
     */
    const menuOptions = [
        { label: '主页', value: 'index', checked: true },
        { label: '分类', value: 'category', checked: true },
        { label: '我的', value: 'my', checked: true }
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* <AdminMenu /> */}
            <Layout>
            {/* <Header style={{ background: '#fff', padding: 0 }} /> */}
            <Content style={{ margin: '0 16px' }}>
                {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>店铺管理</Breadcrumb.Item>
                <Breadcrumb.Item>{useDevice === '1' ? 'H5' : '小程序'}自定义页面</Breadcrumb.Item>
                </Breadcrumb> */}
                <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                    <div className="table-toolbar">
                        <div className="table-toolbar-title">
                            自定义页面列表
                        </div>
                        <div className="table-toolbar-option">
                            <Button onClick={menuButtonHandler} type="primary"><Icon type="menu" />底部菜单设计</Button>
                            <Modal
                                title="底部菜单设计"
                                visible={menuModalVisible}
                                onCancel={ e => { setMenuModalVisible(false) }}
                                onOk={menuModalOkHandler}
                            >
                                <Checkbox.Group 
                                    options={menuOptions}
                                    defaultValue={checkedMenuOptions}
                                    onChange={checkedValues => { setCheckedMenuOptions(checkedValues) }}
                                />
                            </Modal>
                        </div>
                    </div>
                    <Row>
                        <Link to={`/Store/Template/${storeId}/${useDevice}/0`}>
                            <Button style={{width:'100%'}} type="dashed" icon="plus" />
                        </Link>
                    </Row>
                    <Table 
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={list.data}
                        //pagination = {pagination}
                        //onChange={handleTableChange}
                        pagination = {{
                            showSizeChanger: true,
                            onShowSizeChange:(current, size) => {
                                setPageIndex(current);
                                setPageSize(size);
                            },
                            pageSizeOptions: ['8', '12', '24', '36'],
                            showTotal: (total, range) => `共 ${total} 项`,
                            onChange: (page, pageSize) => {
                                setPageIndex(page);
                                setPageSize(pageSize);
                            },
                            defaultPageSize: pageSize,
                            total: list.total
                        }}
                    />
                </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
            </Layout>
        </Layout>
    )
}

export default WhiteCustomPage;