import React, { useContext } from 'react';
import { Form, Input, Divider } from 'antd'
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'

function TitleEdit(){
    const { Item } = Form;
    const { data, dispatch, selectedData } = useContext(TemplateContext);
    
    const inputChange = (e) => {
        selectedData.content.title = e.target.value;
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === selectedData.id);
        copiedItems[foundIndex] = selectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }
    return (
        <>
        <Divider>{selectedData.name}</Divider>
        <Item label="标题文字">
            <Input defaultValue={selectedData.content.title} maxLength={20} onChange={inputChange} />
        </Item>
        </>
    )
}

export default TitleEdit;