import React from 'react';
import { Layout } from 'antd'
import { Template } from './Template'
import Workbench from './Workbench'
import '../../static/style/pages/StoreTempEdit.css'
import '../../static/style/components/template.css'


function StoreTempEdit(props) {
    const { Footer } = Layout;
    return (
        <Layout>
            <Template>
                <Workbench props={props} />
            </Template>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
        </Layout>
    );
}

export default StoreTempEdit;