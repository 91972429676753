import React, { forwardRef, useImperativeHandle } from 'react'
import { Form, Col, Row, Input, Button } from 'antd';

const PaySettingAliPayForm = forwardRef(({ form, data, onSubmit, onClose }, ref) => {
    useImperativeHandle(ref, () => ({
        form
    }))
    const { getFieldDecorator } = form;
    return (
        <Form id="aliPayForm" name="aliPayForm" onSubmit={onSubmit} layout="vertical">
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="AppID">
                {getFieldDecorator('appId', {
                    rules: [{ required: true, message: '请输入AppID' }],
                    initialValue: data.appID
                })(<Input placeholder="AppID" />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="合作伙伴PID">
                {getFieldDecorator('pid', {
                    rules: [{ required: true, message: '请输入合作伙伴PID' }],
                    initialValue: data.pid
                })(<Input placeholder="合作伙伴PID" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="MD5密钥">
                {getFieldDecorator('key', {
                    rules: [{ required: true, message: '请输入MD5密钥' }],
                    initialValue: data.key
                })(<Input placeholder="MD5密钥" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="支付宝公钥">
                {getFieldDecorator('publicKey', {
                    rules: [{required: true, message: '请填写支付宝公钥' }],
                    initialValue: data.publicKey
                })(<Input.TextArea rows={4} placeholder="应用私钥" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="应用私钥">
                {getFieldDecorator('privateKey', {
                    rules: [{ required: true, message: '请填写应用私钥' }],
                    initialValue: data.privateKey
                })(<Input.TextArea rows={4} placeholder="应用私钥" />)}
                </Form.Item>
            </Col>
            </Row>
            <div
                style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
                </Button>
                <Button htmlType="submit" type="primary">
                提交
                </Button>
            </div>
        </Form>
    );
})


export default Form.create()(PaySettingAliPayForm);