import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from './Login'
import Home from './Home'
import Company from './Company'
import PaySetting from './PaySetting'
import Store from './Store'
import StoreTempEdit from './StoreTempEdit/StoreTempEdit'
import Setting from './Setting'
import CustomPage from './CustomPage'
import WhiteCustomPage from './WhiteCustomPage'
import StoreSetting from './StoreSetting'
import Auth from './Auth'

function Main(){
    return (
        <Router>
            <Route path="/" exact component={Login} />
            <Route path="/login/" exact component={Login} />
            <Route path="/home/" exact component={Home} />
            <Route path="/company/" exact component={Company} />
            <Route path="/paysetting/:id" component={PaySetting} />
            <Route path="/store/" exact component={Store} />
            <Route path="/store/template/:id/:id2/:id3" exact component={StoreTempEdit} />
            <Route path="/setting/:id" component={Setting} />
            <Route path="/storesetting/:id" component={StoreSetting} />
            <Route path="/store/:id/custompage/:id2" exact component={CustomPage} />
            <Route path="/auth" component={Auth} />
            <Route path="/store/:id/whitecustompage/:id2" exact component={WhiteCustomPage} />
        </Router>
    )
}
export default Main