import React, { useContext } from 'react';
import { Form, Slider, Divider } from 'antd'
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'

function SpaceEdit(){
    const { Item } = Form;
    const { data, dispatch, selectedData } = useContext(TemplateContext);
    
    const sliderChange = (value) => {
        const copiedComponent = JSON.parse(JSON.stringify(selectedData));//深拷贝，复制对象
        copiedComponent.content.height = value;
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedComponent.id);
        copiedItems[foundIndex] = copiedComponent;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }
    return (
        <>
        <Divider>{selectedData.name}</Divider>
        <Item label="高度">
            <Slider min={10} defaultValue={selectedData.content.height} onChange={sliderChange} tooltipVisible />
        </Item>
        </>
    )
}

export default SpaceEdit;