import React, { useState, useEffect } from 'react';
import { getParam } from '../utils/url'
import { Spin, message } from 'antd'
import axios from '../utils/request'
import { setCookie } from '../utils/cookie'

function Auth(props) {
    const [isLoading, setIsLoading] = useState(true);

    const uid = getParam("uid", props.location.search);
    const companyId = getParam("companyId", props.location.search);
    const storeId = getParam("storeId", props.location.search);
    const token = getParam("token", props.location.search);
    
    const containerStyle = {
        position: 'absolute',
        top: '35%', 
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign:'center'
    }

    useEffect(() => {
        if(uid == null || companyId == null || storeId == null || token == null) {
            message.error('参数不完整');
        }
        else {
            axios.post('/api/Admin/Auth', {
                uid,
                companyId,
                storeId,
                token
            }).then((res) => {
                setIsLoading(false);
                if(res.data.status === 'success'){
                    setCookie('token', res.data.data.tokenContent, new Date(res.data.data.expires))
                    props.history.push(`/Store/${storeId}/WhiteCustomPage/4`);
                }
            })
        }
        
    }, 1)

    return (
        <div style={containerStyle}>
            <Spin tip="授权中" size="large" spinning={isLoading} />
        </div>
    );
}

export default Auth;