import React, { useContext, useState } from 'react';
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import { Input, Select, Modal, Upload, Icon, message, Button } from 'antd'
import uuid from "uuid/v4";
import ModalProductsList from '../ModalProductsList'

function CategoryLevel3Control({ item, index, index2 }) {
    const ButtonGroup = Button.Group;
    //商品Modal状态
    const [modalVisible, setModalVisible] = useState(false);
    //商品类型1.商品 2.旅游
    const [productType, setProductType] = useState(item.linkType.toString());

    /**
     * 下拉框选择函数
     * @param {选中的值} value 
     * @param {组件的索引} index 
     */
    const handleChange = (value) => {
        if(value !== '0'){
            //打开Modal
            setModalVisible(true);
            //设置打开的类型
            setProductType(value);
        }
        content.dataset[currentIndex].sub[index].sub[index2].linkType = Number(value);
        onChange();//改变外界数据
    }

    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);
    const { content } = selectedData;

    let currentIndex = content.dataset.findIndex(p => p.cur === true);
    
    /**
     * 商品列表Modal选择商品之后的回调函数
     * @param {商品} goods 
     */
    const eventChooseGoods = (goods) => {
        content.dataset[currentIndex].sub[index].sub[index2].linkType = Number(productType);
        content.dataset[currentIndex].sub[index].sub[index2].link = goods.url;
        content.dataset[currentIndex].sub[index].sub[index2].title = goods.cname;
        content.dataset[currentIndex].sub[index].sub[index2].pic = goods.pic;
        onChange();//改变外界数据

        //改变当前图片
        setFileList([{
            uid: uuid(),
            name: item.title,
            status: 'done',
            url: item.pic
        }])
        setModalVisible(false);
    }

    //删除三级分类
    const onItemRemove = () => {
        const copidsubs = [...content.dataset[currentIndex].sub[index].sub];
        copidsubs.splice(index2, 1)

        content.dataset[currentIndex].sub[index].sub = copidsubs;

        onChange();
    }

    //三级分类向上移动
    const onUp = () => {
        //当前下标为0则无需移动
        if(index2 === 0) {
            return;
        }

        const copidsubs = [...content.dataset[currentIndex].sub[index].sub]
        const [subRemoved] = copidsubs.splice(index2, 1);
        copidsubs.splice(index2 - 1, 0, subRemoved);

        content.dataset[currentIndex].sub[index].sub = copidsubs;

        onChange();
    }

    //三级分类向下移动
    const onDown = () => {
        //当前下标是最后一个下标则无需移动
        if(index2 + 1 === content.dataset[currentIndex].sub[index].sub.length) {
            return;
        }

        const copidsubs = [...content.dataset[currentIndex].sub[index].sub]
        const [subRemoved] = copidsubs.splice(index2, 1);
        copidsubs.splice(index2 + 1, 0, subRemoved);

        content.dataset[currentIndex].sub[index].sub = copidsubs;

        onChange();
    }

    /**
     * 用户手动输入链接
     * @param e 
     */
    const onLinkChange = e => {
        content.dataset[currentIndex].sub[index].sub[index2].link = e.target.value;
        onChange();
    }

    /**
     * 用户手动输入Title
     * @param {*} e 
     */
    const onTitleChange = e => {
        content.dataset[currentIndex].sub[index].sub[index2].title = e.target.value;
        onChange();
    }

    const onChange = () => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = content;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 上传文件之前的钩子，用于判断图片大小
     * @param {*} file 
     */
    const beforeUpload = (file) => {
        const isLt3M = file.size / 1024 / 1024 < 1;
        if (!isLt3M) {
            message.error('图片大于1MB');
        }
        return isLt3M;
    }

    //当前项目的图片
    const [fileList, setFileList] = useState(item.pic.length > 0 ? [{
        uid: uuid(),
        name: item.title,
        status: 'done',
        url: item.pic
    }] : null);

    /**
     * 上传中、完成、失败都会调用这个函数。
     * @param {*} info 
     */
    const onUploadChange = ({ file, fileList }) => {
        if(file.status === "removed"){
            setFileList(null);
            content.dataset[currentIndex].sub[index].sub[index2].pic = '';
            onChange();
        }
        else if(file.status === "uploading"){
            setFileList([...fileList]);
        }
        else if(file.status === "done"){
            setFileList([...fileList]);
            content.dataset[currentIndex].sub[index].sub[index2].pic = file.response.url
            onChange();//改变外界数据
        }
    }

    /**
     * 没有图片时展示的上传组件
     */
    const uploadButton = (
        <div>
          <Icon type="plus" />
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div key={index2 + item} className="ctrl-list-item">
            <div className="ctrl-list-item-remove">
                <Button shape="circle" 
                    onClick={onItemRemove} 
                    size="small" 
                    style={{border:'0px'}} 
                    icon="close" 
                />
            </div>
            <div className="ctrl-list-item-actions">
                <ButtonGroup>
                    <Button style={{border:'0px'}} 
                        onClick={onUp} 
                        shape="circle" 
                        size="small" 
                        icon="arrow-up" 
                    />
                    <Button style={{border:'0px'}} 
                        onClick={onDown} 
                        shape="circle" 
                        size="small" 
                        icon="arrow-down" 
                    />
                </ButtonGroup>
            </div>
            <div className="ctrl-list-item-img">
                <Upload 
                    key={currentIndex + item.title + index2}
                    action="/api/File/AntdUpload"
                    accept="image/jpg,image/png,image/jpeg"
                    showUploadList={{
                        showDownloadIcon: false//去除下载按钮
                    }}
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    fileList={ fileList }
                >
                    { fileList == null ? uploadButton : null }
                </Upload>
            </div>
            <div className="ctrl-list-item-info">
                <div className="ctrl-form-item">
                    <label>链接：</label>
                    <div className="ctrl-form-controls">
                        <Input addonAfter={
                            (
                                <Select 
                                    defaultValue={productType}
                                    onSelect={(value)=>{handleChange(value)}}  
                                    style={{ width: 100 }}
                                >
                                    <Select.Option value="0">自定义</Select.Option>
                                    <Select.Option value="1">选择商品</Select.Option>
                                    <Select.Option value="2">选择线路</Select.Option>
                                </Select>
                            )
                        } 
                        onChange={onLinkChange}
                        value={item.link}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>名称：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            onChange={onTitleChange}
                            value={item.title}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title="选择商品"
                closable
                visible={modalVisible}
                footer={null}
                maskClosable={false}
                width={900}
                onCancel={()=>{setModalVisible(false)}}
            >
                <ModalProductsList productType={productType} eventChoose={eventChooseGoods} />
            </Modal>
        </div>
    )
}

export default CategoryLevel3Control;