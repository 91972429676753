import React from 'react';
import "video-react/dist/video-react.css";
import { Player } from 'video-react'

function Video01({ content }) {
    return (
        <div
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }}
            className="diy-conitem-video01"
        >
            <Player 
                poster={content.poster}
                src={content.src}
            />
        </div>
    )
}

export default Video01;