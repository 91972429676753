import React from 'react';

//导航，4个
function Nav({ content }){
    return (
        <div 
        style={{
            marginTop:content.marginTop,
            marginBottom:content.marginBottom,
            marginLeft:content.marginLR,
            marginRight:content.marginLR
        }} 
        className="diy-conitem-nav">
            <ul>
                {
                    content.dataset.map((item, index) => {
                        return (
                            <li key={index}
                                style={{
                                    paddingLeft:content.gapSpace/2,
                                    paddingRight:content.gapSpace/2
                                }}
                            >
                                <img src={item.pic} alt={item.title} /><span>{item.title}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export default Nav;