import axios from 'axios'
import { message } from 'antd'
import { getCookie } from './cookie'

if(process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://localhost:5001/';
}
else {
  axios.defaults.baseURL = '/';
}


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let authToken = getCookie('token');
    //添加验证登录token
    if(authToken){
        config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    //console.log(config);
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    //console.log(response);
    // 对响应数据做点什么
    if(response.status === 200){
        //Http返回200
        if(response.data.status === 'fail'){
            //返回业务消息
            message.warning(response.data.message);
        }
    }
    return response;
  }, 
  function (error) {
    //console.log(error);

    const { response } = error
    if (error === undefined || error.code === 'ECONNABORTED' || response === undefined) {
        message.warning('服务请求超时')
        return Promise.reject(error)
    }
    
    if(response.status === 401){
        //Http返回401
        //跳转到登录页面
        window.location.href = '/login/';
    }
    else if(response.status === 404){
        //Http返回404
    }
    else if(response.status === 500){
        //Http返回500
        message.error('服务器开小差了');
    }

    // 对响应错误做点什么
    return Promise.reject(error);
  });


export default axios;