import React, { useContext } from 'react';
import { Button, Divider } from 'antd'
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import GoodsControl from './GoodsControl'
import GoodsPropertyControl from './GoodsPropertyControl'

function GoodsEdit({ limit, defaultDataFunc }) {
    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);
    
    const { content } = selectedData;

    /**
     * 控件改变的回调
     * @param {循环的index} index 
     * @param {改变后的数据} itemData 
     */
    const onChange = (index, itemData) => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        if(itemData === null){
            //删除
            copiedSelectedData.content.dataset.splice(index, 1);
        }
        else{
            //改变或新增
            copiedSelectedData.content.dataset[index] = itemData;
        }
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 控件属性改变的回调
     * @param {*} contentData 
     */
    const onChangeProp = contentData => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content = contentData;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        //console.log(copiedSelectedData)
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 新增控件
     */
    const onAdd = () => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset.push(defaultDataFunc());
        selectedDispatch(copiedSelectedData);
        
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 把元素往上移动
     * @param {当前移动元素的下标} index 
     */
    const onUp = (index) => {
        //当前下标为0则无需移动
        if(index === 0) {
            return;
        }
        const copiedDatasets = [...selectedData.content.dataset];
        const [dataSetRemoved] = copiedDatasets.splice(index, 1);
        copiedDatasets.splice(index - 1, 0, dataSetRemoved);

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset = copiedDatasets;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 把元素往下移动
     * @param {当前移动元素的下标} index 
     */
    const onDown = (index) => {
        //当前下标是最后一个下标则无需移动
        if(index + 1 === selectedData.content.dataset.length){
            return;
        }

        const copiedDatasets = [...selectedData.content.dataset];
        const [dataSetRemoved] = copiedDatasets.splice(index, 1);
        copiedDatasets.splice(index + 1, 0, dataSetRemoved);

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset = copiedDatasets;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 新增控件按钮
     */
    const AddButton = (
        <Button style={{width:'100%'}} type="dashed" onClick={onAdd} icon="plus" />
    );

    return (
        <div className="ctrl-list">
            <Divider>{selectedData.name}</Divider>
            <GoodsPropertyControl key="goodsProperty" contentData={content} onChangeProp={onChangeProp} />
            {
                content.dataset.map((item, index) => {
                    return (
                        <GoodsControl 
                            key={index + item.pic}
                            index={index} 
                            itemData={item} 
                            onChange={onChange} 
                            onUp={onUp} 
                            onDown={onDown}
                        />
                    )
                })
            }
            {
                content.dataset.length < limit ? AddButton : null
            }
        </div>
    )
}

export default GoodsEdit;