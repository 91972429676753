import React, { useContext } from 'react';
import { Divider } from 'antd'
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import CategoryTabControl from './CategoryTabControl'
import CategoryLevel2Control from './CategoryLevel2Control'

function CategoryEdit({ defaultDataFunc }) {
    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);

    /**
     * 把元素往上移动
     * @param {当前移动元素的下标} index 
     */
    const onUp = (index) => {
        //当前下标为0则无需移动
        if(index === 0) {
            return;
        }
        const copiedDatasets = [...selectedData.content.dataset];
        const [dataSetRemoved] = copiedDatasets.splice(index, 1);
        copiedDatasets.splice(index - 1, 0, dataSetRemoved);

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset = copiedDatasets;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 把元素往下移动
     * @param {当前移动元素的下标} index 
     */
    const onDown = (index) => {
        //当前下标是最后一个下标则无需移动
        if(index + 1 === selectedData.content.dataset.length){
            return;
        }

        const copiedDatasets = [...selectedData.content.dataset];
        const [dataSetRemoved] = copiedDatasets.splice(index, 1);
        copiedDatasets.splice(index + 1, 0, dataSetRemoved);

        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset = copiedDatasets;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    /**
     * 新增控件
     */
    const onAdd = () => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset.push(defaultDataFunc());
        selectedDispatch(copiedSelectedData);
        
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    return (
        <div className="ctrl-list">
            <Divider>{selectedData.name}</Divider>
            <div className="ctrl-category-content">
                <CategoryTabControl
                    onUp={onUp} 
                    onDown={onDown}
                    onAdd={onAdd}
                />
                <CategoryLevel2Control key="categoryLevel2" />
            </div>
            
        </div>
    )
}

export default CategoryEdit;