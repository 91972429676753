import React, { useEffect, useState } from 'react';
import { List, Avatar, Button, Row, Col, Input } from 'antd'
import axios from '../utils/request'
import { withRouter } from 'react-router-dom';

/**
 * 商品列表的Modal
 * @param {店铺ID,选择产品的回调函数} param0 
 */
function ModalProductsList({ productType, eventChoose, match }){
    const [list, setList] = useState([]);
    const [title, setTitle] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const { Search } = Input;
    //店铺ID
    const storeId = match.params.id;
    const getListQuery = (pageIndex, pageSize, storeId, productType, title) => {
        let requestUrl = '';
        if(productType === '1'){
            requestUrl = `/api/Goods/ModalList?pageIndex=${pageIndex}&pageSize=${pageSize}&title=${title}`;
        }
        else if(productType === '2'){
            requestUrl = `/api/Line/ModalList?pageIndex=${pageIndex}&pageSize=${pageSize}&title=${title}`;
        }
        axios.get(requestUrl, {
            headers:{
                'StoreID': storeId,
                'AppKey': match.params.id2 === 1 ? 'm3csxA2GVoBgV7oltiXPgogwf6D6SwWl' //1.H5
                : 'ez4Gmav372EfSSOKevZDJZXGByrV8Ys7'//4.小程序
            }
        })
        .then(
            (res) => {
                setList(res.data);
            }
        )
    }

    const searchHandler = value => {
        setTitle(value);
    }
    
    useEffect(() => {
        getListQuery(pageIndex, pageSize, storeId, productType, title);
    }, [storeId, productType, title, pageIndex, pageSize])

    return (
        <div className="modal-goods-list">
            <Row>
                <Col span={6}></Col>
                <Col span={12}><Search placeholder="请输入" size="default" onSearch={searchHandler} enterButton /></Col>
                <Col span={6}></Col>
            </Row>
            <List
                dataSource={list.data}
                pagination = {{
                    showTotal: (total, range) => `共 ${total} 项`,
                    //onChange: (pageIndex, pageSize) => getListQuery(pageIndex, pageSize, storeId, productType),
                    onChange: (pageIndex, pageSize) => {
                        setPageIndex(pageIndex);
                        setPageSize(pageSize);
                    },
                    defaultPageSize: 8,
                    total: list.total
                }}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta 
                            avatar={<Avatar shape="square" size="large" src={item.pic} />}
                            title={item.cname + (item.describe.length > 0 ? '(' + item.describe + ')' : '')}
                            //description={'￥' + item.price}
                            description={item.currencySymbol + item.price}
                        />
                        <Button onClick={() => { eventChoose(item) }}>选取</Button>
                    </List.Item>
                )}
            >
                
            </List>
        </div>
    )
}

export default withRouter(ModalProductsList);