import React from 'react';

//商品组件
function Goods01({ content }){

    /**
     * 产品标签组件
     * @param {}} param0 
     */
    const GoodsTags = ({ item }) => {
        if(item.tags !== null && item.tags !== undefined) {
            return (
                <div className="goods01-st06-tags">
                    {
                        item.tags.map((tag, index) => {
                            return (<span key={index}>{tag}</span>)
                        })
                    }
                </div>
            )
        }
        else {
            return (<></>)
        }
    }

    if(content.showType === 1) {
        //大图模式-1行1个
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-goods01-st01">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div key={index} className="goods01-st01-item">
                                <img 
                                    style={{
                                        borderRadius: content.borderRadius
                                    }}
                                    className="goods01-st01-img" 
                                    src={item.pic} 
                                    alt={item.title} 
                                />
                                <div className="goods01-st01-tit">
                                    {item.title}
                                </div>
                                <div className="goods01-st01-price">
                                    ￥{item.price}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    else if(content.showType === 2) {
        //小图模式-1行2个
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-goods01-st02">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div 
                                key={index} 
                                style={{
                                    borderRadius: content.borderRadius
                                }}
                                className="goods01-st02-item"
                            >
                                <img 
                                    style={{
                                        borderTopLeftRadius: content.borderRadius,
                                        borderTopRightRadius: content.borderRadius
                                    }}
                                    className="goods01-st02-img" 
                                    src={item.pic} 
                                    alt={item.title} 
                                />
                                <div className="goods01-st02-tit">
                                    {item.title}
                                </div>
                                <div className="goods01-st02-price">
                                    ￥{item.price}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    else if(content.showType === 3) {
        //小图模式-1行3个
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-goods01-st03">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div 
                            key={index} 
                            style={{
                                borderRadius: content.borderRadius
                            }}
                            className="goods01-st03-item">
                                <img 
                                    style={{
                                        borderTopLeftRadius: content.borderRadius,
                                        borderTopRightRadius: content.borderRadius
                                    }}
                                    className="goods01-st03-img" 
                                    src={item.pic} alt={item.title} 
                                />
                                <div className="goods01-st03-tit">
                                    {item.title}
                                </div>
                                <div className="goods01-st03-price">
                                    ￥{item.price}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    else if(content.showType === 4) {
        //大图列表
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-goods01-st04">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div key={index} className="goods01-st04-item">
                                <div className="goods01-st04-left">
                                    <img 
                                        style={{
                                            borderRadius: content.borderRadius
                                        }}
                                        className="goods01-st04-img" 
                                        src={item.pic} alt={item.title} 
                                    />
                                </div>
                                <div className="goods01-st04-right">
                                    <div className="goods01-st04-tit">
                                        {item.title}
                                    </div>
                                    <div className="goods01-st04-desc">
                                        {item.describe}
                                    </div>
                                    <div className="goods01-st04-price">
                                        ￥{item.price}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    else if(content.showType === 5) {
        //小图列表
        return (
            <div 
                style={{
                    marginTop:content.marginTop,
                    marginBottom:content.marginBottom,
                    marginLeft:content.marginLR,
                    marginRight:content.marginLR
                }} 
                className="diy-conitem-goods01-st05">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div key={index} className="goods01-st05-item">
                                <div className="goods01-st05-left">
                                    <img 
                                        style={{
                                            borderRadius: content.borderRadius
                                        }}
                                        className="goods01-st05-img" 
                                        src={item.pic} alt={item.title} 
                                    />
                                </div>
                                <div className="goods01-st05-right">
                                    <div className="goods01-st05-tit">
                                        {item.title}
                                    </div>
                                    <div className="goods01-st05-desc">
                                        {item.describe}
                                    </div>
                                    <div className="goods01-st05-price">
                                        ￥{item.price}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    else if(content.showType === 6) {
        //瀑布流
        return (
            <div 
                style={{
                    marginTop:content.marginTop,
                    marginBottom:content.marginBottom,
                    marginLeft:content.marginLR,
                    marginRight:content.marginLR
                }} 
                className="diy-conitem-goods01-st06">
                {
                    content.dataset.map((item, index) => {
                        return (
                            <div key={index} 
                                style={{
                                    borderRadius: content.borderRadius
                                }}
                            className="goods01-st06-item">
                                <img
                                    style={{
                                        borderTopLeftRadius: content.borderRadius,
                                        borderTopRightRadius: content.borderRadius
                                    }}
                                    className="goods01-st06-img" 
                                    src={item.pic} alt={item.title} 
                                />
                                <div className="goods01-st06-tit">
                                    {item.title}
                                </div>
                                <div className="goods01-st06-price">
                                    ￥{item.price}
                                </div>
                                <GoodsTags key={index} item={item} />
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    else {
        return (<></>);
    }
}

export default Goods01;