import React, { useContext } from 'react';
import { TemplateContext } from '../../Pages/StoreTempEdit/Template'
import { Input, Icon, Button } from 'antd'

function CategoryTabControl({ onUp, onDown, onAdd }) {

    const { data, dispatch, selectedData, selectedDispatch } = useContext(TemplateContext);
    const { content } = selectedData;

    //删除分类
    const onCategoryRemove = (index) => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset.splice(index, 1);
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        //console.log("删除后", copiedItems);
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }
    
    //修改一级分类title
    const onTitleChange = (index, e) => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset[index].title = e.target.value;
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //一级分类title点击
    const onTitleClick = i => {
        const copiedSelectedData = JSON.parse(JSON.stringify(selectedData));
        copiedSelectedData.content.dataset.map((item, index) => {
            if(i === index) {
                return item.cur = true;
            }
            else {
                return item.cur = false;
            }
        })
        selectedDispatch(copiedSelectedData);

        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === copiedSelectedData.id);
        copiedItems[foundIndex] = copiedSelectedData;
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    return (
        <div className="ctrl-category-tab">
            <ul>
                {
                    content.dataset.map((item, index) => {
                        return (
                            <li key={index + item}>
                                <Input value={item.title} onClick={e => onTitleClick(index)} onChange={e => onTitleChange(index, e)} suffix=
                                    {
                                    <>
                                        <Icon onClick={()=>onUp(index)} type="arrow-up" /> 
                                        <Icon onClick={()=>onDown(index)} type="arrow-down" /> 
                                        <Icon onClick={()=>onCategoryRemove(index)} type="close" />
                                    </>
                                    } 
                                />
                            </li>
                        )
                    })
                }
                <li>
                    <Button style={{width:'100%'}} type="dashed" onClick={onAdd} icon="plus" />
                </li>
            </ul>
        </div>
    )
}

export default CategoryTabControl;