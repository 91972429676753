import React, { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Tabs, Form, Input, Divider, Anchor, Row, Col, Spin, Switch } from 'antd';
import axios from '../utils/request'
import AdminMenu from '../Components/AdminMenu'

function StoreSetting(props) {
    const { Header, Content, Footer } = Layout;
    const { TabPane } = Tabs;
    const { Link } = Anchor;

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
    };

    const settingKeys = {
        wxMsgPaid: 'Wx_Msg_Paid',
        menuMyRebate: 'Menu_MyRebate',
        lineHideId: 'Line_HideId',
        showAdviser: 'Show_Adviser',
        adviserTitle: 'Adviser_Title'
    }

    const [isLoading, setIsLoading] = useState(true)

    const [wxMsgPaid, setWxMsgPaid] = useState('')

    const [menuMyRebate, setMenuMyRebate] = useState('1');

    const [lineHideId, setLineHideId] = useState('0');

    const [showAdviser, setShowAdviser] = useState('1');

    const [adviserTitle, setAdviserTitle] = useState('旅行顾问');


    //根据key获取value
    const getValue = (data, key) => {
        let foundIndex = data.findIndex(p => p.key === key);
        if(foundIndex > -1){
            return data[foundIndex].value;
        }
        else{
            return '';
        }
    }

    //获取公司ID
    let storeId = props.match.params.id;

    const setValue = (value, key) => {
        axios.post(`/api/TenantSetting/Store/${storeId}`,{
            key: key,
            value: value
        })
        .then((res) => {
            
        })
    }

    //获取公司所有配置
    const getSettings = () => {
        axios.get(`/api/TenantSetting/Store/${storeId}`)
        .then(
            (res) => {
                let data = res.data.data;
                setWxMsgPaid(getValue(data, settingKeys.wxMsgPaid));
                setMenuMyRebate(getValue(data, settingKeys.menuMyRebate));
                setLineHideId(getValue(data, settingKeys.lineHideId));
                setShowAdviser(getValue(data, settingKeys.showAdviser));
                setAdviserTitle(getValue(data, settingKeys.adviserTitle));
                setIsLoading(false);
            }
        )
    }

    useEffect(() => {
        getSettings();
    }, [storeId])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminMenu />
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }} />
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>店铺管理</Breadcrumb.Item>
                    <Breadcrumb.Item>设置</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="微信设置" key="1">
                                    <Row>
                                        <Col span={19}>
                                            <Divider id="msg" orientation="center">小程序订阅消息</Divider>
                                            <Form layout="horizontal">
                                                <Form.Item {...formItemLayout} label="支付成功消息模板ID">
                                                    <Input 
                                                        value={wxMsgPaid} 
                                                        onChange={e=>{setWxMsgPaid(e.target.value);setValue(e.target.value, settingKeys.wxMsgPaid)}}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={4}>
                                            <Anchor>
                                                <Link href="#msg" title="小程序订阅消息" />
                                            </Anchor>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="我的" key="2">
                                    <Row>
                                        <Col span={19}>
                                            <Divider id="msg" orientation="center">个人中心</Divider>
                                            <Form layout="horizontal">
                                                <Form.Item {...formItemLayout} label="我的返佣">
                                                    <Switch checked={menuMyRebate === '1'} onChange={checked=>{setMenuMyRebate(checked?'1':'0');setValue(checked?'1':'0',settingKeys.menuMyRebate)}} />
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={4}>
                                            <Anchor>
                                                <Link href="#msg" title="小程序订阅消息" />
                                            </Anchor>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="线路设置" key="3">
                                    <Row>
                                        <Col span={19}>
                                            <Divider id="hideId" orientation="center">显示</Divider>
                                            <Form layout="horizontal">
                                                <Form.Item {...formItemLayout} label="隐藏ID">
                                                    <Switch checked={lineHideId === '1'} onChange={checked=>{setLineHideId(checked?'1':'0');setValue(checked?'1':'0',settingKeys.lineHideId)}} />
                                                </Form.Item>
                                                <Form.Item {...formItemLayout} label="显示顾问">
                                                    <Switch checked={showAdviser === '1'} onChange={checked=>{setShowAdviser(checked?'1':'0');setValue(checked?'1':'0',settingKeys.showAdviser)}} />
                                                </Form.Item>
                                                <Form.Item {...formItemLayout} label="顾问Title">
                                                    <Input 
                                                        value={adviserTitle} 
                                                        onChange={e=>{setAdviserTitle(e.target.value);setValue(e.target.value, settingKeys.adviserTitle)}}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={4}>
                                            <Anchor>
                                                <Link href="#hideId" title="显示" />
                                            </Anchor>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Ant Design ©2021 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    )
}

export default StoreSetting;