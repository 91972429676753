import React from 'react';
import { Image01Item } from '../../Pages/StoreTempEdit/TemplateData'

function Image01({ content }){
    if(content.dataset.length > 0){
        return (
            <div 
            style={{
                marginTop:content.marginTop,
                marginBottom:content.marginBottom,
                marginLeft:content.marginLR,
                marginRight:content.marginLR
            }} 
            className="diy-conitem-image01">
                <img src={content.dataset[0].pic} alt={content.dataset[0].title} />
            </div>
        )
    }
    else{
        return (
            <div className="diy-conitem-image01">
                <img src={Image01Item.pic} alt={Image01Item.title} />
            </div>
        )
    }
}

export default Image01;