import React, { createContext, useReducer } from 'react';
import { defaultData } from './TemplateData'
import uuid from "uuid/v4";

export const TemplateContext = createContext({})

//新旧数据替换
const reducer = (oldData, newData) => {
    return newData;
}

//当前选中的数据
const reducer2 = (oldData, newData) => {
    return newData;
}

//当前的店铺ID
const reducer3 = (oldData, newData) => {
    return newData;
}

//当前设备
const reducer4 = (oldData, newData) => {
    return newData;
}

//当前页面ID
const reducer5 = (oldData, newData) => {
    return newData;
}

export const Template = props => {
    const [data, dispatch] = useReducer(reducer, defaultData);
    const [selectedData, selectedDispatch] = useReducer(reducer2, { id: uuid() });
    const [storeId, storeIdDispatch] = useReducer(reducer3, 0);

    const [device, deviceDispatch] = useReducer(reducer4, 0);
    const [pageId, pageIdDispatch] = useReducer(reducer5, 0);
    return (
        <TemplateContext.Provider value={{data, dispatch, 
        selectedData, selectedDispatch, 
        storeId, storeIdDispatch,
        device, deviceDispatch,
        pageId, pageIdDispatch
        }}>
            {props.children}
        </TemplateContext.Provider>
    )
}