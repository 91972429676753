import React,{ useContext } from 'react';
import { Empty } from 'antd';
import { TemplateContext } from './Template'
import TitleEdit from '../../Components/Temp/TitleEdit'
import SpaceEdit from '../../Components/Temp/SpaceEdit'
import ImageEdit from '../../Components/Temp/ImageEdit'
import GoodsEdit from '../../Components/Temp/GoodsEdit'
import VideoEdit from '../../Components/Temp/VideoEdit'
import LineEdit from '../../Components/Temp/LineEdit'
import CategoryEdit from '../../Components/Temp/CategoryEdit'
import { ImageDataItem, Image01Item, Image02Item, Image03Item, Image04Item, GoodsDataItem, LineDataItem, CategoryDataItem } from './TemplateData'

function PropertyBox(){
    //选中的组件值
    const { selectedData } = useContext(TemplateContext);

    return (
        <>
        {
            selectedData.type === 0 ? <TitleEdit key={selectedData.id} />:
            selectedData.type === 1 ? <ImageEdit key={selectedData.id} limit={6} defaultDataFunc={ImageDataItem} />:
            selectedData.type === 2 ? <SpaceEdit key={selectedData.id} />:
            selectedData.type === 3 ? <ImageEdit key={selectedData.id} limit={4} defaultDataFunc={Image04Item} />:
            selectedData.type === 4 ? <ImageEdit key={selectedData.id} limit={1} defaultDataFunc={Image01Item} />:
            selectedData.type === 5 ? <ImageEdit key={selectedData.id} limit={2} defaultDataFunc={Image02Item} />:
            selectedData.type === 6 ? <ImageEdit key={selectedData.id} limit={3} defaultDataFunc={Image03Item} />:
            selectedData.type === 7 ? <ImageEdit key={selectedData.id} limit={4} defaultDataFunc={Image04Item} />:
            selectedData.type === 8 ? <GoodsEdit key={selectedData.id} limit={999} defaultDataFunc={GoodsDataItem} />:
            selectedData.type === 9 ? <LineEdit key={selectedData.id} limit={999} defaultDataFunc={LineDataItem} />:
            selectedData.type === 10 ? <VideoEdit key={selectedData.id} />:
            selectedData.type === 11 ? <CategoryEdit key={selectedData.id} defaultDataFunc={CategoryDataItem} />:
            <Empty />
        }
        </>
    )
}

export default PropertyBox;