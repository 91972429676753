/**
 * 设置cookie
 * @param {cookie名称} name 
 * @param {cookie值} value 
 * @param {过期时间} expiresDate 
 */
export const setCookie = (name, value, expiresDate) => {
    var expires = "";
    if (Object.prototype.toString.call(expiresDate) === '[object Date]') {
        expires = "; expires=" + expiresDate.toUTCString();
    }
    else {
        //没有定义过期时间就默认1天
        var date = new Date();
        date.setTime(date.getTime() + (1*24*60*60*1000));
        expires = "; expires=" + expiresDate.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
 * 获取cookie
 * @param {cookie名称} name 
 */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/**
 * 抹去cookie
 * @param {cookie名称} name 
 */
export const eraseCookie = (name) => {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}