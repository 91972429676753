import React,{ useContext } from 'react';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { TemplateContext } from './Template'
import Title from '../../Components/Temp/Title'
import Ad from '../../Components/Temp/Ad'
import Space from '../../Components/Temp/Space'
import Nav from '../../Components/Temp/Nav'
import Image01 from '../../Components/Temp/Image01'
import Image02 from '../../Components/Temp/Image02'
import Image03 from '../../Components/Temp/Image03'
import Image04 from '../../Components/Temp/Image04'
import Goods01 from '../../Components/Temp/Goods01'
import Line01 from '../../Components/Temp/Line01'
import Video01 from '../../Components/Temp/Video01'
import Category from '../../Components/Temp/Category'

function PhoneBox(){
    const { 
        data, 
        dispatch, 
        selectedData, //选中的组件值
        selectedDispatch
    } = useContext(TemplateContext);

    //点击组件事件
    const clickComponent = (e, component) => {
        e.stopPropagation();//阻止事件冒泡
        selectedDispatch(component);
    }

    //删除组件事件
    const deleteComponent = (e, component) => {
        e.stopPropagation();
        if(component === selectedData){
            selectedDispatch({ type: -1});
        }
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === component.id);
        copiedItems.splice(foundIndex, 1);
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //上移组件
    const upComponent = (e, component) => {
        e.stopPropagation();
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === component.id);
        if(foundIndex === 0){
            return;
        }
        const [componentRemoved] = copiedItems.splice(foundIndex, 1);
        copiedItems.splice(foundIndex - 1, 0, componentRemoved);
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    //下移组件
    const downComponent = (e, component) => {
        e.stopPropagation();
        const copiedItems = [...data.Modules];
        let foundIndex = copiedItems.findIndex(p => p.id === component.id);
        if(foundIndex + 1 === copiedItems.length){
            return;
        }
        const [componentRemoved] = copiedItems.splice(foundIndex, 1);
        copiedItems.splice(foundIndex + 1, 0, componentRemoved);
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }

    return (
        <div className="phone-box">
            <div className="phone-box-header"></div>
            <div className="phone-box-content"
                style={{
                    backgroundColor:data.page.bgColor
                }}
            >
                <Droppable droppableId="workbench" key="workbench">
                    {
                        (provided, snapshot) => {
                            return (
                            <div 
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="phone-box-contain"
                            >
                                {
                                    data.Modules.map((item, index) => {
                                        return (
                                            <Draggable 
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                            >
                                                {
                                                    (provided, snapshot) => {
                                                        return (
                                                            <div 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={selectedData.id === item.id ? 'diy-conitem selected' : 'diy-conitem'}
                                                                onClick={(e) => {clickComponent(e, item)}}
                                                            >
                                                                {
                                                                    item.type === 0 ?
                                                                    <Title key={item.id} content={item.content} />
                                                                    : item.type === 1 ?
                                                                    <Ad key={item.id} content={item.content} />
                                                                    : item.type === 2 ?
                                                                    <Space key={item.id} content={item.content} />
                                                                    : item.type === 3 ?
                                                                    <Nav key={item.id} content={item.content} />
                                                                    : item.type === 4 ?
                                                                    <Image01 key={item.id} content={item.content} />
                                                                    : item.type === 5 ?
                                                                    <Image02 key={item.id} content={item.content} />
                                                                    : item.type === 6 ?
                                                                    <Image03 key={item.id} content={item.content} />
                                                                    : item.type === 7 ?
                                                                    <Image04 key={item.id} content={item.content} />
                                                                    : item.type === 8 ?
                                                                    <Goods01 key={item.id} content={item.content} />
                                                                    : item.type === 9 ?
                                                                    <Line01 key={item.id} content={item.content} />
                                                                    : item.type === 10 ?
                                                                    <Video01 key={item.id} content={item.content} />
                                                                    : item.type === 11 ?
                                                                    <Category key={item.id} content={item.content} />
                                                                    : ''
                                                                }
                                                                <div className="diy-conitem-action">
                                                                    <div className="diy-conitem-action-btns">
                                                                        <button onClick={(e) => {upComponent(e, item)}} className="diy-conitem-btn">上移</button>
                                                                        <button onClick={(e) => {downComponent(e, item)}} className="diy-conitem-btn">下移</button>
                                                                        <button onClick={(e) => {deleteComponent(e, item)}} className="diy-conitem-btn">删除</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            </Draggable>
                                        )
                                        
                                    })
                                }
                            </div>);
                        }
                    }
                </Droppable>
            </div>
            <div className="phone-box-footer"></div>
        </div>
    )
}

export default PhoneBox;