import React from 'react';
import { Slider } from 'antd'

function BasePropertyControl({contentData, onChangeProp}) {
    /**
     * 改变上边距
     * @param {} value 
     */
    const onMarginTopChange = value => {
        contentData.marginTop = value;
        onChangeProp(contentData);
    }

    /**
     * 改变下边距
     * @param {}} value 
     */
    const onMarginBottomChange = value => {
        contentData.marginBottom = value;
        onChangeProp(contentData);
    }

    /**
     * 改变左右边距
     * @param {*} value 
     */
    const onMarginLRChange = value => {
        contentData.marginLR = value;
        onChangeProp(contentData);
    }
    

    return (
        <div className="ctrl-list-property">
            <div className="ctrl-form-item">
                <label>上边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginTop} onChange={onMarginTopChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>下边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginBottom} onChange={onMarginBottomChange} />
                </div>
            </div>
            <div className="ctrl-form-item">
                <label>左右边距：</label>
                <div className="ctrl-form-controls">
                <Slider defaultValue={contentData.marginLR} onChange={onMarginLRChange} />
                </div>
            </div>
        </div>
    )
}

export default BasePropertyControl;