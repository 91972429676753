import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import AdminMenu from '../Components/AdminMenu'
import 'antd/dist/antd.css'
import '../static/style/pages/Home.css'

function Home(){
    const { Header, Content, Footer } = Layout;

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminMenu />
            <Layout>
            <Header style={{ background: '#fff', padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>User</Breadcrumb.Item>
                <Breadcrumb.Item>Bill</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>Bill is a cat.</div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    );
}

export default Home;

