import React,{ useEffect, useState } from 'react';
import { Layout, Breadcrumb, Tabs, Form, Input, Divider, Anchor, Row, Col, Switch, Spin, InputNumber, Radio } from 'antd';
import axios from '../utils/request'
import AdminMenu from '../Components/AdminMenu'

function Setting(props){
    const { Header, Content, Footer } = Layout;
    const { TabPane } = Tabs;
    const { Link } = Anchor;

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
    };

    const settingKeys = {
        smsType: 'Sms_Type',
        smsZTUsername: 'Sms_ZT_UserName',
        smsZTPassword: 'Sms_ZT_Password',
        smsZTProductid: 'Sms_ZT_Productid',
        emailHost: 'Email_Host',
        emailPort: 'Email_Port',
        emailUseSsl: 'Email_UseSsl',
        emailUsername: 'Email_UserName',
        emailUserAddress: 'Email_UserAddress',
        emailPassword: 'Email_Password',
        expressKd100Key: 'Kuaidi100_Key',
        expressKd100Customer: 'Kuaidi100_Customer',
        paymentTimeout: 'Payment_Timeout' //支付超时
    }

    const [isLoading, setIsLoading] = useState(true)

    const [smsType, setSmsType] = useState(0);
    const [smsZTUsername, setSmsZTUsername] = useState('');
    const [smsZTPassword, setSmsZTPassword] = useState('');
    const [smsZTProductid, setSmsZTProductid] = useState('');

    const [emailHost, setEmailHost] = useState('');
    const [emailPort, setEmailPort] = useState('');
    const [emailUseSsl, setEmailUseSsl] = useState(true);
    const [emailUsername, setEmailUsername] = useState('');
    const [emailUserAddress, setEmailUserAddress] = useState('');
    const [emailPassword, setEmailPassword] = useState('');


    const [expressKd100Key, setExpressKd100Key] = useState('');
    const [expressKd100Customer, setExpressKd100Customer] = useState('');

    const [paymentTimeout, setPaymentTimeout] = useState(30);

    //根据key获取value
    const getValue = (data, key) => {
        let foundIndex = data.findIndex(p => p.key === key);
        if(foundIndex > -1){
            return data[foundIndex].value;
        }
        else{
            return '';
        }
    }
    
    //获取公司ID
    let companyId = props.match.params.id;

    const setValue = (value, key) => {
        axios.post(`/api/TenantSetting/${companyId}`,{
            key: key,
            value: value
        })
        .then((res) => {
            
        })
    }
    
    //获取公司所有配置
    const getSettings = () => {
        axios.get(`/api/TenantSetting/${companyId}`)
        .then(
            (res) => {
                let data = res.data.data;
                setSmsType(Number(getValue(data, settingKeys.smsType)))
                setSmsZTUsername(getValue(data, settingKeys.smsZTUsername));
                setSmsZTPassword(getValue(data, settingKeys.smsZTPassword));
                setSmsZTProductid(getValue(data, settingKeys.smsZTProductid));

                setEmailHost(getValue(data, settingKeys.emailHost));
                setEmailPort(getValue(data, settingKeys.emailPort));
                setEmailUseSsl(getValue(data, settingKeys.emailUseSsl) !== '0');
                setEmailUsername(getValue(data, settingKeys.emailUsername));
                setEmailUserAddress(getValue(data, settingKeys.emailUserAddress));
                setEmailPassword(getValue(data, settingKeys.emailPassword));

                setExpressKd100Key(getValue(data, settingKeys.expressKd100Key));
                setExpressKd100Customer(getValue(data, settingKeys.expressKd100Customer));

                setPaymentTimeout(getValue(data, settingKeys.paymentTimeout));
                setIsLoading(false);
            }
        )
    }

    useEffect(() => {
        getSettings();
    })

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminMenu />
            <Layout>
            <Header style={{ background: '#fff', padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>公司管理</Breadcrumb.Item>
                <Breadcrumb.Item>设置</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <Spin tip="Loading..." spinning={isLoading}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="常规设置" key="1">
                            <Row>
                                <Col span={19}>
                                    <Divider id="order" orientation="center">订单/支付</Divider>
                                    <Form layout="horizontal">
                                        <Form.Item {...formItemLayout} label="支付超时时间">
                                            <InputNumber 
                                                value={paymentTimeout} 
                                                onChange={value=>{setPaymentTimeout(value);setValue(value, settingKeys.paymentTimeout)}} 
                                            />
                                            <span className="ant-form-text"> 分钟</span>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={1}>
                                </Col>
                                <Col span={4}>
                                    <Anchor>
                                        <Link href="#order" title="订单/支付" />
                                    </Anchor>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="第三方设置" key="2">
                            <Row>
                                <Col span={19}>
                                    <Divider id="ztsms" orientation="center">短信平台</Divider>
                                    <Form layout="horizontal">
                                        <Form.Item {...formItemLayout} label="平台类型">
                                        <Radio.Group 
                                            onChange={e=>{setSmsType(e.target.value);setValue(e.target.value, settingKeys.smsType)}} 
                                            defaultValue={smsType}
                                        >
                                            <Radio.Button value={0}>老助通</Radio.Button>
                                            <Radio.Button value={1}>新助通</Radio.Button>
                                        </Radio.Group>
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="用户名">
                                            <Input value={smsZTUsername} onChange={e=>{setSmsZTUsername(e.target.value);setValue(e.target.value, settingKeys.smsZTUsername)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="密码">
                                            <Input value={smsZTPassword} onChange={e=>{setSmsZTPassword(e.target.value);setValue(e.target.value, settingKeys.smsZTPassword)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="产品ID">
                                            <Input value={smsZTProductid} onChange={e=>{setSmsZTProductid(e.target.value);setValue(e.target.value, settingKeys.smsZTProductid)}} />
                                        </Form.Item>
                                    </Form>
                                    <Divider id="smtp" orientation="center">邮件服务器</Divider>
                                    <Form>
                                        <Form.Item {...formItemLayout} label="邮件服务地址">
                                            <Input value={emailHost} onChange={e=>{setEmailHost(e.target.value);setValue(e.target.value, settingKeys.emailHost)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="邮件服务端口">
                                            <Input value={emailPort} onChange={e=>{setEmailPort(e.target.value);setValue(e.target.value, settingKeys.emailPort)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="是否开启SSL">
                                            <Switch checked={emailUseSsl} onChange={(checked, event)=>{setEmailUseSsl(checked);setValue(checked ? '1' : '0', settingKeys.emailUseSsl)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="邮箱用户名">
                                            <Input value={emailUsername} onChange={e=>{setEmailUsername(e.target.value);setValue(e.target.value, settingKeys.emailUsername)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="邮箱地址">
                                            <Input value={emailUserAddress} onChange={e=>{setEmailUserAddress(e.target.value);setValue(e.target.value, settingKeys.emailUserAddress)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="邮箱密码">
                                            <Input value={emailPassword} onChange={e=>{setEmailPassword(e.target.value);setValue(e.target.value, settingKeys.emailPassword)}} />
                                        </Form.Item>
                                    </Form>
                                    <Divider id="kd100" orientation="center">快递100</Divider>
                                    <Form>
                                        <Form.Item {...formItemLayout} label="授权KEY">
                                            <Input value={expressKd100Key} onChange={e=>{setExpressKd100Key(e.target.value);setValue(e.target.value, settingKeys.expressKd100Key)}} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="customer">
                                            <Input value={expressKd100Customer} onChange={e=>{setExpressKd100Customer(e.target.value);setValue(e.target.value, settingKeys.expressKd100Customer)}} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={1}>
                                </Col>
                                <Col span={4}>
                                    <Anchor>
                                        <Link href="#ztsms" title="助通短信平台" />
                                        <Link href="#smtp" title="邮件服务器" />
                                        <Link href="#kd100" title="快递100" />
                                    </Anchor>
                                </Col>
                            </Row>
                        </TabPane>
                        {/* <TabPane tab="微信设置" key="3">
                            <Row>
                                <Col span={19}>
                                    <Divider id="msg" orientation="center">小程序订阅消息</Divider>
                                    <Form layout="horizontal">
                                        <Form.Item {...formItemLayout} label="支付成功模板ID">
                                            <Input />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </TabPane> */}
                    </Tabs>
                </Spin>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    )
}
export default Setting;