import React, { forwardRef, useImperativeHandle } from 'react'
import { Form, Col, Row, Input, Button } from 'antd';

const PaySettingWechatPayForm = forwardRef(({ form, data, onSubmit, onClose }, ref) => {
    useImperativeHandle(ref, () => ({
        form
    }))
    const { getFieldDecorator } = form;
    return (
        <Form id="wechatPayForm" name="wechatPayForm" onSubmit={onSubmit} layout="vertical">
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="AppID">
                {getFieldDecorator('appId', {
                    rules: [{ required: true, message: '请输入AppID' }],
                    initialValue: data.appID
                })(<Input placeholder="AppID" />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="AppSecret">
                {getFieldDecorator('appSecret', {
                    rules: [{ required: true, message: '请输入AppSecret' }],
                    initialValue: data.appSecret
                })(<Input placeholder="AppSecret" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="商户号">
                {getFieldDecorator('mchId', {
                    rules: [{ required: true, message: '请输入商户号' }],
                    initialValue: data.mchid
                })(<Input placeholder="商户号" />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="API密钥">
                {getFieldDecorator('key', {
                    rules: [{ required: true, message: '请输入API密钥' }],
                    initialValue: data.key
                })(<Input placeholder="API密钥" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="证书">
                {getFieldDecorator('publicKey', {
                    rules: [{required: true, message: '请填写证书' }],
                    initialValue: data.publicKey
                })(<Input.TextArea rows={4} placeholder="apiclient_cert.pem" />)}
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="证书密钥">
                {getFieldDecorator('privateKey', {
                    rules: [{required: true, message: '请填写证书密钥' }],
                    initialValue: data.privateKey
                })(<Input.TextArea rows={4} placeholder="apiclient_key.pem" />)}
                </Form.Item>
            </Col>
            </Row>
            <div
                style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
                </Button>
                <Button htmlType="submit" type="primary">
                提交
                </Button>
            </div>
        </Form>
    );
})
export default Form.create()(PaySettingWechatPayForm);