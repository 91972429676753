import React, { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Card, Icon, Drawer, List, Col, Row } from 'antd';
import { Link } from 'react-router-dom'
import axios from '../utils/request'
import AdminMenu from '../Components/AdminMenu'

function Store(){
    const { Meta } = Card;
    const { Header, Content, Footer } = Layout;
    
    const [visible, setVisible] = useState(false);

    const onClose = () => {
        setVisible(false);
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const [list, setList] = useState([]);

    const getList = (pageIndex, pageSize) => {
        axios.get(`/api/Store/List?pageIndex=${pageIndex}&pageSize=${pageSize}`)
        .then(
            (res) => {
                setList(res.data);
            }
        )
    }

    const [data, setData] = useState({});
    const getDataInfo = (id) => {
        axios.get(`/api/Store/${id}`)
        .then(
            (res) => {
                if(!visible){
                    setData(res.data.data);
                    showDrawer();
                }
            }
        )
    }

    useEffect(() => {
        getList(1, 12);
    }, []);

    const DescriptionItem = ({ title, content }) => (
        <div
          style={{
            fontSize: 14,
            lineHeight: '22px',
            marginBottom: 7,
            color: 'rgba(0,0,0,0.65)',
          }}
        >
          <p
            style={{
              marginRight: 8,
              display: 'inline-block',
              color: 'rgba(0,0,0,0.85)',
            }}
          >
            {title}:
          </p>
          {content}
        </div>
    );

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminMenu />
            <Layout>
            <Header style={{ background: '#fff', padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>店铺管理</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                    <List
                        grid={{ gutter: 16, column: 4 }}
                        dataSource={list.data}
                        pagination = {{
                            showSizeChanger: true,
                            onShowSizeChange: getList,
                            pageSizeOptions: ['8', '12', '24', '36'],
                            showTotal: (total, range) => `共 ${total} 项`,
                            onChange: getList,
                            defaultPageSize: 12,
                            total: list.total
                        }}
                        renderItem={item => (
                        <List.Item>
                            <Card
                                style={{ width: 300 }}
                                actions={[
                                <Link to={`/Store/${item.id}/CustomPage/1`}><Icon type="html5" /></Link>,
                                <Link to={`/Store/${item.id}/CustomPage/4`}><Icon type="wechat" /></Link>,
                                <Icon type="info" key="info" 
                                    onClick={() => getDataInfo(item.id)} 
                                />,
                                <Link to={'/storesetting/' + item.id}><Icon type="setting" key="setting" /></Link>,
                                ]}
                            >
                                <Meta
                                title={item.storeCname}
                                description={item.storeUrl.length === 0 ? ' ' : item.storeUrl}
                                />
                            </Card>
                        </List.Item>
                        )}
                    />
                    
                    <Drawer
                        width={640}
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        visible={visible}
                        >
                        <Row>
                            <Col span={12}>
                            <DescriptionItem title="店铺名称" content={data.storeCname} />
                            </Col>
                            <Col span={12}>
                            <DescriptionItem title="英文名称" content={data.storeName} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                            <DescriptionItem title="网址" content={data.storeUrl} />
                            </Col>
                            <Col span={12}>
                            <DescriptionItem title="邮编" content={data.postCode} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                            <DescriptionItem title="电话" content={data.tel} />
                            </Col>
                            <Col span={12}>
                            <DescriptionItem title="传真" content={data.fax} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                            <DescriptionItem title="网站地址" content={data.webSite} />
                            </Col>
                            <Col span={12}>
                            <DescriptionItem title="ERP地址" content={data.tEurl} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                            <DescriptionItem title="负责人" content={data.corporation} />
                            </Col>
                            <Col span={12}>
                            <DescriptionItem title="是否使用" content={data.useFlag === 0 ? '使用' : '停用'} />
                            </Col>
                        </Row>
                    </Drawer>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    )
}

export default Store;