import React, { useState, useRef } from 'react';
import { Input, Modal, Upload, Icon, message, Button, Tag } from 'antd'
import uuid from "uuid/v4";
import ModalProductsList from '../ModalProductsList'

function LineControl({ index, itemData, onChange, onUp, onDown }) {
    const ButtonGroup = Button.Group;
    //商品Modal状态
    const [modalVisible, setModalVisible] = useState(false);
    //商品类型固定为线路
    const productType = '2';

    //当前项目的图片
    const [fileList, setFileList] = useState(itemData.pic.length > 0 ? [{
        uid: uuid(),
        name: itemData.title,
        status: 'done',
        url: itemData.pic
    }] : null);
    /**
     * 商品列表Modal选择商品之后的回调函数
     * @param {商品} goods 
     */
    const eventChooseGoods = (goods) => {
        itemData.linkType = Number(productType);
        itemData.link = goods.url;
        itemData.title = goods.cname;
        itemData.pic = goods.pic;
        itemData.price = goods.price;
        itemData.describe = goods.describe;
        itemData.tags = goods.tags;
        onChange(index, itemData);//改变外界数据

        //改变当前图片
        setFileList([{
            uid: uuid(),
            name: itemData.cname,
            status: 'done',
            url: itemData.pic
        }])
        setModalVisible(false);
    }

    /**
     * 没有图片时展示的上传组件
     */
    const uploadButton = (
        <div>
          <Icon type="plus" />
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    /**
     * 上传中、完成、失败都会调用这个函数。
     * @param {*} info 
     */
    const onUploadChange = ({ file, fileList }) => {
        if(file.status === "removed"){
            setFileList(null);
            itemData.pic = '';
            onChange(index, itemData);
        }
        else if(file.status === "uploading"){
            setFileList([...fileList]);
        }
        else if(file.status === "done"){
            setFileList([...fileList]);
            itemData.pic = file.response.url
            onChange(index, itemData);//改变外界数据
        }
    }

    /**
     * 用户手动输入链接
     * @param e 
     */
    const onLinkChange = e => {
        itemData.link = e.target.value;
        onChange(index, itemData);
    }

    /**
     * 用户手动输入Title
     * @param {*} e 
     */
    const onTitleChange = e => {
        itemData.title = e.target.value;
        //itemData.showTitle = e.target.value;
        onChange(index, itemData);
    }
    

    /**
     * 用户手动输入Describe
     * @param {*} e 
     */
    const onDescribeChange = e => {
        itemData.describe = e.target.value;
        onChange(index, itemData);
    }

    /**
     * 用户手动输入Price
     * @param {*} e 
     */
    const onPriceChange = e => {
        itemData.price = Number(e.target.value);
        onChange(index, itemData);
    }

    /**
     * 用户手动输入setOut
     * @param {*} e 
     */
    const onSetOutChange = e => {
        itemData.setOut = e.target.value;
        onChange(index, itemData);
    }

    /**
     * 上传文件之前的钩子，用于判断图片大小
     * @param {*} file 
     */
    const beforeUpload = (file) => {
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
            message.error('图片大于3MB');
        }
        return isLt3M;
    }

    //打开选择商品的弹出层
    const openGoodsModal = () => {
        //打开Modal
        setModalVisible(true);
    }

    /**
     * 删除该项
     */
    const onItemRemove = () => {
        onChange(index, null);
    }

    //标签的Input组件状态
    const [inputVisible, setInputVisible] = useState(true);
    const [inputValue, setInputValue] = useState('');

    /**
     * 标签元素
     * @param {*} param0 
     */
    const TagsElem = ({ tags }) => {
        return (
            <>
                {
                    tags.map((tag, index) => {
                        return (
                            <Tag 
                                key={index} 
                                closable
                                onClose={ () => removedTagHandle(tag) }
                            >
                                {tag}
                            </Tag>
                        )
                    })
                }
                <NewTag key={index + 'newTag'} />
            </>
        )

        // if(tags !== null && tags !== undefined) {
        //     return (
        //         <>
        //             {
        //                 tags.map((tag, index) => {
        //                     return (
        //                         <Tag 
        //                             key={index + tag} 
        //                             closable
        //                             onClose={ () => removedTagHandle(tag) }
        //                         >
        //                             {tag}
        //                         </Tag>
        //                     )
        //                 })
        //             }
        //             <NewTag />
        //         </>
        //     )
        // }
        // else {
        //     return (
        //         <NewTag />
        //     )
        // }
    }

    const tagInputRef = useRef();

    //标签input修改
    const onTagsInputChange = e => {
        //console.log(tagInputRef)
        //console.log(tagInputRef.current)
        setInputValue(e.target.value)
        tagInputRef.current.focus();
    }

    //显示标签input
    const showTagsInput = () => {
        setInputVisible(true);
        //console.log(tagInputRef)
        //console.log(tagInputRef.current)
    }
    

    /**
     * 添加Tag按钮
     */
    const NewTag = () => {
        return (
            <>
            {inputVisible && (
                <Input
                    //key="tagInput"
                    //key={index + itemData + 'newTagInput'}
                    ref={tagInputRef}
                    //key={uuid()}
                    id={'tagsInput' + index}
                    key={'tagsInput' + index}
                    type="text"
                    size="small"
                    style={{ width: 78 }}
                    value={inputValue}
                    onChange={onTagsInputChange}
                    onBlur={tagInputConfirm}
                    onPressEnter={tagInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag 
                    onClick={showTagsInput} 
                    style={{ background: '#fff', borderStyle: 'dashed' }}>
                    <Icon type="plus" /> New Tag
                </Tag>
            )}
            </>
        )
    }

    /**
     * 删除标签
     * @param {} tag 
     */
    const removedTagHandle = removedTag => {
        itemData.tags = itemData.tags.filter(tag => tag !== removedTag);
        onChange(index, itemData);
    }

    /**
     * 标签输入确认
     */
    const tagInputConfirm = () => {
        if (inputValue && itemData.tags.indexOf(inputValue) === -1) {
            itemData.tags = [...itemData.tags, inputValue];
            onChange(index, itemData);
        }
        setInputValue('');
        setInputVisible(false);
    }

    return (
        <div className="ctrl-list-item">
            <div className="ctrl-list-item-remove">
                <Button 
                shape="circle" 
                onClick={onItemRemove} 
                size="small" 
                style={{border:'0px'}} 
                icon="close" />
            </div>
            <div className="ctrl-list-item-actions">
                <ButtonGroup>
                    <Button style={{border:'0px'}} onClick={() => { onUp(index) }} shape="circle" size="small" icon="arrow-up" />
                    <Button style={{border:'0px'}} onClick={() => { onDown(index) }} shape="circle" size="small" icon="arrow-down" />
                </ButtonGroup>
            </div>
            <div className="ctrl-list-item-img">
                <Upload 
                    key={index}
                    action="/api/File/AntdUpload"
                    accept="image/jpg,image/png,image/jpeg"
                    showUploadList={{
                        showDownloadIcon: false//去除下载按钮
                    }}
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    fileList={ fileList }
                >
                    { fileList == null ? uploadButton : null }
                </Upload>
            </div>
            <div className="ctrl-list-item-info">
                <div className="ctrl-form-item">
                    <label>链接：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                        addonAfter={
                            (
                                <Icon type="shop" onClick={openGoodsModal} />
                            )
                        } 
                        key="txtLink"
                        //style={{width:290}}
                        onChange={onLinkChange}
                        value={itemData.link}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>标题：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            key={"txtLineTitle" + index}
                            //style={{width:290}}
                            onChange={onTitleChange}
                            value={itemData.title}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>简介：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            key={"txtLineDesc" + index}
                            //style={{width:290}}
                            onChange={onDescribeChange}
                            value={itemData.describe}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>价格：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            key={'txtLinePrice' + index}
                            prefix={itemData.currencySymbol}
                            suffix={itemData.currencyShortName}
                            //style={{width:290}}
                            onChange={onPriceChange}
                            value={itemData.price}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>出发地：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            key={'txtSetOut' + index}
                            //style={{width:290}}
                            onChange={onSetOutChange}
                            value={itemData.setOut}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>标签：</label>
                    <div className="ctrl-form-controls">
                        <TagsElem key={index + 'tags'} tags={itemData.tags} />
                    </div>
                </div>
            </div>
            <Modal
                title="选择旅游产品"
                closable
                visible={modalVisible}
                footer={null}
                maskClosable={false}
                width={900}
                onCancel={()=>{setModalVisible(false)}}
            >
                <ModalProductsList productType={productType} eventChoose={eventChooseGoods} />
            </Modal>
        </div>
    )
}

export default LineControl;