import React, { useContext } from 'react';
import { Menu, Icon } from 'antd'
import { Droppable } from "react-beautiful-dnd";
import { TitleData, AdData, SpaceData, NavData, Image01Data, Image02Data, Image03Data, Image04Data, Goods01Data, Line01Data, VideoData, CategoryData } from './TemplateData'
import { TemplateContext } from './Template'

function ToolBox(){
    const { SubMenu } = Menu;

    const { data, dispatch } = useContext(TemplateContext);

    //添加新的组件
    const addComponent = (component) => {
        const copiedItems = [...data.Modules];
        copiedItems.push(component);//加入到数组
        dispatch({
            page: data.page,
            Modules: copiedItems
        });
    }
    return (
        <Droppable droppableId="toolbox" key="toolbox">
            {
                (provided, snapshot) => {
                    return (
                        <div 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="tool-box"
                        >
                            <Menu
                                mode="inline"
                                selectable={false}
                                defaultOpenKeys={['sub1']}
                                style={{ height: '100%', borderRight: 0 }}
                                >
                                <SubMenu
                                    key="sub1"
                                    title={
                                    <span>
                                        <Icon type="user" />
                                        基础组件
                                    </span>
                                    }
                                >
                                    <Menu.Item key="1" onClick={()=>{addComponent(TitleData())}}>标题组件</Menu.Item>
                                    <Menu.Item key="2" onClick={()=>{addComponent(AdData())}}>广告组件</Menu.Item>
                                    <Menu.Item key="3" onClick={()=>{addComponent(SpaceData())}}>空白组件</Menu.Item>
                                    <Menu.Item key="4" onClick={()=>{addComponent(NavData())}}>导航组件</Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="sub2"
                                    title={
                                    <span>
                                        <Icon type="picture" />
                                        图片组件
                                    </span>
                                    }
                                >
                                    <Menu.Item key="5" onClick={()=>{addComponent(Image01Data())}}>通栏图片</Menu.Item>
                                    <Menu.Item key="6" onClick={()=>{addComponent(Image02Data())}}>二分图片</Menu.Item>
                                    <Menu.Item key="7" onClick={()=>{addComponent(Image03Data())}}>三分图片</Menu.Item>
                                    <Menu.Item key="8" onClick={()=>{addComponent(Image04Data())}}>四分图片</Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="sub3"
                                    title={
                                    <span>
                                        <Icon type="appstore" />
                                        高级组件
                                    </span>
                                    }
                                >
                                    <Menu.Item key="9" onClick={()=>{addComponent(Goods01Data())}}>商品组件</Menu.Item>
                                    <Menu.Item key="10" onClick={()=>{addComponent(Line01Data())}}>旅游产品组件</Menu.Item>
                                    <Menu.Item key="11" onClick={()=>{addComponent(VideoData())}}>视频组件</Menu.Item>
                                    <Menu.Item key="12" onClick={()=>{addComponent(CategoryData())}}>类别组件</Menu.Item>
                                </SubMenu>
                            </Menu>
                        </div>
                    )
                }
            }
        </Droppable>
    );
}

export default ToolBox;