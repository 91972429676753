import React, { useContext, useState } from 'react';
import { Layout, Row, Col, Input, Button, message, Form } from 'antd'
import { TemplateContext } from './Template'
import axios from '../../utils/request'
import { SketchPicker } from 'react-color'
import creatHistory from 'history/createHashHistory'

function HeaderBox({props}){
    const { Header } = Layout;
    const { data, dispatch, storeId, device, pageId } = useContext(TemplateContext);

    const { title, description, url, bgColor  } = data.page;

    const history = creatHistory();

    const changeTitle = e => {
        let copiedPage = JSON.parse(JSON.stringify(data.page));
        copiedPage.title = e.target.value;
        dispatch({
            page: copiedPage,
            Modules: data.Modules
        })
    }

    const changeDescription = e => {
        let copiedPage = JSON.parse(JSON.stringify(data.page));
        copiedPage.description = e.target.value;
        dispatch({
            page: copiedPage,
            Modules: data.Modules
        })
    }

    const changeUrl = e => {
        let copiedPage = JSON.parse(JSON.stringify(data.page));
        copiedPage.url = e.target.value;
        dispatch({
            page: copiedPage,
            Modules: data.Modules
        })
    }

    const changeBgColor = color => {
        let copiedPage = JSON.parse(JSON.stringify(data.page));
        copiedPage.bgColor = color.hex;
        dispatch({
            page: copiedPage,
            Modules: data.Modules
        })
    }

    const onSave = () => {
        if(title.trim().length === 0){
            message.warning('请填写页面标题');
            return false;
        }
        if(description.trim().length === 0){
            message.warning('请填写页面简介');
            return false;
        }
        if(url.trim().length === 0){
            message.warning('请填写页面url');
            return false;
        }

        axios.put(`/api/CustomPage/${storeId}/${device}/${pageId}`, data)
        .then(
            (res) => {
                console.log(res)
                if(res.status === 200 && res.data.status === 'success'){
                    message.success('保存成功');
                    //props.history.push(`/Store/${storeId}/CustomPage/${device}`);
                }
            }
        )
    }

    //是否打开选择颜色模块
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    

    return (
        <Header 
            style={{ background: '#fff', borderBottom:'1px solid #ccc', height:'auto' }} 
            className="header">
            <Row>
                <Col span={24}>
                    <Form layout="inline">
                        <Form.Item>
                        <Button onClick={()=>{history.goBack()}} type="default">返回</Button>
                        </Form.Item>
                        <Form.Item>
                        <Input value={title} onChange={changeTitle} placeholder="页面标题" />
                        </Form.Item>
                        <Form.Item>
                        <Input value={description} onChange={changeDescription} placeholder="页面简介" />
                        </Form.Item>
                        <Form.Item>
                        <Input value={url} maxLength={15} onChange={changeUrl} placeholder="页面url，只能是英文数字，不能有符号" />
                        </Form.Item>
                        <Form.Item>
                        <Input 
                            addonBefore="#" 
                            placeholder="页面背景颜色" 
                            value={ bgColor === undefined ? '' : bgColor.substring(1)}
                            onClick={() => setDisplayColorPicker(!displayColorPicker)}
                            addonAfter={
                                <div style={{
                                    width:18,
                                    height:18,
                                    backgroundColor:bgColor
                                }} />
                            }
                        />
                        {
                            displayColorPicker ?
                            <div style={{
                                position:'absolute',
                                zIndex:2
                            }}>
                                <div 
                                style={{
                                    position:'fixed',
                                    top:'0px',
                                    right:'0px',
                                    bottom:'0px',
                                    left:'0px'
                                }}
                                onClick={() => setDisplayColorPicker(false)}
                                />
                                <SketchPicker
                                    disableAlpha={true}
                                    color={bgColor}
                                    onChange={changeBgColor}
                                    presetColors={['#ffffff','#f1f1f1','#e1e1e1','#d1d1d1','#c1c1c1','#b1b1b1']}
                                />
                            </div>
                            : null
                        }
                        </Form.Item>
                        <Form.Item>
                        <Button onClick={onSave} type="primary">保存</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {/* <Row>
                <Col span={2}>
                    <Button type="default">
                        <Link to={`/Store/${storeId}/CustomPage/${device}`}>返回</Link>
                    </Button>
                </Col>
                <Col span={4}>
                    <Input value={title} onChange={changeTitle} placeholder="页面标题" />
                </Col>
                <Col span={1}>
                </Col>
                <Col span={4}>
                    <Input value={description} onChange={changeDescription} placeholder="页面简介" />
                </Col>
                <Col span={1}>
                </Col>
                <Col span={4}>
                    <Input value={url} maxLength={15} onChange={changeUrl} placeholder="页面url，只能是英文数字，不能有符号" />
                </Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                    <Input addonBefore="#" placeholder="背景颜色" />
                </Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                    <Button onClick={onSave} type="primary">保存</Button>
                </Col>
            </Row> */}
        </Header>
    )
}

export default HeaderBox;