import React, { useState } from 'react';
import { Input, Select, Modal, Upload, Icon, message, Button } from 'antd'
import uuid from "uuid/v4";
import ModalProductsList from '../ModalProductsList'

/**
 * 
 * @param {<当前循环的index>,<当前的数据>,<改变数据的回调>,<上移回调>,<下移回调>} param0 
 * 
 */
function ImageControl({ index, itemData, onChange, onUp, onDown }){
    
    const ButtonGroup = Button.Group;
    //商品Modal状态
    const [modalVisible, setModalVisible] = useState(false);
    //商品类型1.商品 2.旅游
    const [productType, setProductType] = useState(itemData.linkType.toString());
    //console.log(itemData)
    //当前项目的图片
    const [fileList, setFileList] = useState(itemData.pic.length > 0 ? [{
        uid: uuid(),
        name: itemData.title,
        status: 'done',
        url: itemData.pic
    }] : null);

    /**
     * 下拉框选择函数
     * @param {选中的值} value 
     * @param {组件的索引} index 
     */
    const handleChange = (value) => {
        if(value !== '0'){
            //打开Modal
            setModalVisible(true);
            //设置打开的类型
            setProductType(value);
        }
        itemData.linkType = Number(value);
        onChange(index, itemData);//改变外界数据
    }

    /**
     * 商品列表Modal选择商品之后的回调函数
     * @param {商品} goods 
     */
    const eventChooseGoods = (goods) => {
        itemData.linkType = Number(productType);
        itemData.link = goods.url;
        itemData.title = goods.cname;
        //itemData.showTitle = goods.cname;
        itemData.pic = goods.pic;
        onChange(index, itemData);//改变外界数据

        //改变当前图片
        setFileList([{
            uid: uuid(),
            name: itemData.cname,
            status: 'done',
            url: itemData.pic
        }])
        setModalVisible(false);
    }

    /**
     * 没有图片时展示的上传组件
     */
    const uploadButton = (
        <div>
          <Icon type="plus" />
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    /**
     * 上传中、完成、失败都会调用这个函数。
     * @param {*} info 
     */
    const onUploadChange = ({ file, fileList }) => {
        if(file.status === "removed"){
            setFileList(null);
            itemData.pic = '';
            onChange(index, itemData);
        }
        else if(file.status === "uploading"){
            setFileList([...fileList]);
        }
        else if(file.status === "done"){
            setFileList([...fileList]);
            itemData.pic = file.response.url
            onChange(index, itemData);//改变外界数据
        }
    }

    /**
     * 用户手动输入链接
     * @param e 
     */
    const onLinkChange = e => {
        let linkValue = e.target.value;
        itemData.link = linkValue;
        onChange(index, itemData);
    }

    /**
     * 用户手动输入Title
     * @param {*} e 
     */
    const onTitleChange = e => {
        let titleValue = e.target.value;
        itemData.title = titleValue;
        //itemData.showTitle = titleValue;
        onChange(index, itemData);
    }

    /**
     * 用户手动输入图片路径
     * @param {*} e 
     */
    const onPicChange = e => {
        let picValue = e.target.value;
        itemData.pic = picValue;
        onChange(index, itemData);
    }

    /**
     * 上传文件之前的钩子，用于判断图片大小
     * @param {*} file 
     */
    const beforeUpload = (file) => {
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
            message.error('图片大于3MB');
        }
        return isLt3M;
    }

    /**
     * 删除该项
     */
    const onItemRemove = () => {
        onChange(index, null);
    }

    return (
        <div key={index} className="ctrl-list-item">
            <div className="ctrl-list-item-remove">
                <Button shape="circle" onClick={onItemRemove} size="small" style={{border:'0px'}} icon="close" />
            </div>
            <div className="ctrl-list-item-actions">
                <ButtonGroup>
                    <Button style={{border:'0px'}} onClick={() => { onUp(index) }} shape="circle" size="small" icon="arrow-up" />
                    <Button style={{border:'0px'}} onClick={() => { onDown(index) }} shape="circle" size="small" icon="arrow-down" />
                </ButtonGroup>
            </div>
            <div className="ctrl-list-item-img">
                <Upload 
                    key={index}
                    action="/api/File/AntdUpload"
                    accept="image/jpg,image/png,image/jpeg"
                    showUploadList={{
                        showDownloadIcon: false//去除下载按钮
                    }}
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    fileList={ fileList }
                >
                    { fileList == null ? uploadButton : null }
                </Upload>
            </div>
            <div className="ctrl-list-item-info">
                <div className="ctrl-form-item">
                    <label>链接：</label>
                    <div className="ctrl-form-controls">
                        <Input addonAfter={
                            (
                                <Select 
                                    defaultValue={productType}
                                    onSelect={(value)=>{handleChange(value)}}  
                                    style={{ width: 100 }}
                                >
                                    <Select.Option value="0">自定义</Select.Option>
                                    <Select.Option value="1">选择商品</Select.Option>
                                    <Select.Option value="2">选择线路</Select.Option>
                                </Select>
                            )
                        } 
                        //key="txtLink"
                        //id="txtLink"
                        onChange={onLinkChange}
                        value={itemData.link}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>导航名称：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            //key="txtTitle"
                            //id="txtTitle"
                            //suffix={<span>{itemData.showTitle}</span>}
                            onChange={onTitleChange}
                            value={itemData.title}
                        />
                    </div>
                </div>
                <div className="ctrl-form-item">
                    <label>图片路径：</label>
                    <div className="ctrl-form-controls">
                        <Input 
                            //key="txtTitle"
                            //id="txtTitle"
                            //suffix={<span>{itemData.showTitle}</span>}
                            onChange={onPicChange}
                            value={itemData.pic}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title="选择商品"
                closable
                visible={modalVisible}
                footer={null}
                maskClosable={false}
                width={900}
                onCancel={()=>{setModalVisible(false)}}
            >
                <ModalProductsList productType={productType} eventChoose={eventChooseGoods} />
            </Modal>
        </div>
    );
}

export default ImageControl;