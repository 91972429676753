import React from 'react';

//标题组件
function Title(props){
    return (
        <div className="diy-conitem-title">
            {
                props.content.title
            }
        </div>
    )
}

export default Title;