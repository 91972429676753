import React, { useState } from 'react';
import { Card, Input, Icon, Button, Spin, Form } from 'antd';
import axios from '../utils/request'
import { setCookie } from '../utils/cookie'
import 'antd/dist/antd.css'
import '../static/style/pages/Login.css'

function Login(props) {
    //console.log(process.env.NODE_ENV)
    const [isLoading, setIsLoading] = useState(false)
    const handleSubmit = e =>{
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsLoading(true);
                axios.post('/api/Admin/Login', values)
                .then(
                    (res) => {
                        setIsLoading(false);
                        if(res.data.status === 'success'){
                            setCookie('token', res.data.data.tokenContent, new Date(res.data.data.expires))
                            props.history.push('/home');
                        }
                    }
                )
            }
        });
    }
    
    const { getFieldDecorator } = props.form;

    return (
        <>
            <div className="logo-div">
                <label>畅游商城Saas系统</label>
            </div>
            <div className="login-div">
                <Spin tip="Loading..." spinning={isLoading}>
                    <Card title="管理员登陆" bordered={true} style={{ width: 400 }} >
                        <Form onSubmit={handleSubmit}>
                            <Form.Item>
                                {getFieldDecorator('userName', {
                                    rules: [{ required: true, message: '请输入用户名' }],
                                })(
                                    <Input
                                        id="userName"
                                        size="large"
                                        placeholder="用户名"
                                        prefix={<Icon type="user" style={{color:'rgba(0,0,0,.25)'}} />}
                                    /> ,
                                )}
                                
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: '请输入密码' }],
                                })(
                                    <Input.Password
                                        id="password"
                                        size="large"
                                        placeholder="密码"
                                        prefix={<Icon type="key" style={{color:'rgba(0,0,0,.25)'}} />}
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large" block> 登 录 </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </div>
        </>
    )
}

export default Form.create()(Login);