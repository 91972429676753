import React,{ useEffect, useState } from 'react';
import { Layout, Breadcrumb, Card, Icon, Drawer, List, Col, Row, Input } from 'antd';
import { Link } from 'react-router-dom'
import axios from '../utils/request'
import AdminMenu from '../Components/AdminMenu'

function Company(){
    const { Meta } = Card;
    const { Header, Content, Footer } = Layout;
    const { Search } = Input;
    
    const [visible, setVisible] = useState(false);

    const [title, setTitle] = useState('');

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(12);

    const onClose = () => {
        setVisible(false);
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const [list, setList] = useState([]);

    const getList = (title, pageIndex, pageSize) => {
        axios.get(`/api/Company/List?pageIndex=${pageIndex}&pageSize=${pageSize}&title=${title}`)
        .then(
            (res) => {
                setList(res.data);
            }
        )
    }

    const searchHandler = value => {
        setTitle(value);
    }


    const [data, setData] = useState({
        id: 0,
        regType: 0,
        cname: "",
        shortName: "",
        ename: "",
        shortPinYin: "",
        association: "",
        continent: 0,
        province: 0,
        cityId: 0,
        borough: 0,
        address: "",
        eaddress: "",
        postCode: "",
        tel: "",
        fax: "",
        webSite: "",
        corporation: "",
        tEurl: "",
        regCode: "",
        useFlag: 0,
        updateCount: 0,
        stopTrading: 0,
        logoUrl: "",
        secLogo: ""
    });
    const getDataInfo = (id) => {
        axios.get(`/api/Company/${id}`)
        .then(
            (res) => {
                if(!visible){
                    setData(res.data.data);
                    showDrawer();
                }
            }
        )
    }

    useEffect(() => {
        getList(title, pageIndex, pageSize);
    }, [title, pageIndex, pageSize])

    
    const DescriptionItem = ({ title, content }) => (
        <div
          style={{
            fontSize: 14,
            lineHeight: '22px',
            marginBottom: 7,
            color: 'rgba(0,0,0,0.65)',
          }}
        >
          <p
            style={{
              marginRight: 8,
              display: 'inline-block',
              color: 'rgba(0,0,0,0.85)',
            }}
          >
            {title}:
          </p>
          {content}
        </div>
      );
    

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AdminMenu />
            <Layout>
            <Header style={{ background: '#fff', padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>公司管理</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ padding: 24, background: '#fff', minHeight: 60, marginBottom: 25 }}>
                    <Row>
                        <Col span={8}></Col>
                        <Col span={8}><Search placeholder="请输入" size="default" onSearch={searchHandler} enterButton /></Col>
                        <Col span={8}></Col>
                    </Row>
                
                </div>
                <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                    <List
                        grid={{ gutter: 16, column: 4 }}
                        dataSource={list.data}
                        pagination = {{
                            showSizeChanger: true,
                            onShowSizeChange:(current, size) => {
                                setPageIndex(current);
                                setPageSize(size);
                            },
                            pageSizeOptions: ['8', '12', '24', '36'],
                            showTotal: (total, range) => `共 ${total} 项`,
                            onChange: (page, pageSize) => {
                                setPageIndex(page);
                                setPageSize(pageSize);
                            },
                            defaultPageSize: pageSize,
                            total: list.total
                        }}
                        renderItem={item => (
                        <List.Item>
                            <Card
                                style={{ width: 300 }}
                                actions={[
                                <Link to={'/setting/' + item.id}><Icon type="setting" key="setting" /></Link>,
                                <Link to={'/paysetting/' + item.id}><Icon type="property-safety" key="pay" /></Link>,
                                <Icon type="info" key="info" onClick={() => getDataInfo(item.id)} />,
                                ]}
                            >
                                <Meta
                                title={item.cname}
                                description={item.ename.length === 0 ? ' ' : item.ename}
                                />
                            </Card>
                        </List.Item>
                        )}
                    />
                    
                        <Drawer
                            width={640}
                            placement="right"
                            closable={false}
                            onClose={onClose}
                            visible={visible}
                            >
                            <Row>
                                <Col span={12}>
                                <DescriptionItem title="公司名称" content={data.cname} />
                                </Col>
                                <Col span={12}>
                                <DescriptionItem title="英文名称" content={data.ename} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                <DescriptionItem title="地址" content={data.address} />
                                </Col>
                                <Col span={12}>
                                <DescriptionItem title="邮编" content={data.postCode} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                <DescriptionItem title="电话" content={data.tel} />
                                </Col>
                                <Col span={12}>
                                <DescriptionItem title="传真" content={data.fax} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                <DescriptionItem title="网站地址" content={data.webSite} />
                                </Col>
                                <Col span={12}>
                                <DescriptionItem title="ERP地址" content={data.tEurl} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                <DescriptionItem title="负责人" content={data.corporation} />
                                </Col>
                                <Col span={12}>
                                <DescriptionItem title="是否使用" content={data.useFlag === 0 ? '使用' : '停用'} />
                                </Col>
                            </Row>
                        </Drawer>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    );
}

export default Company;